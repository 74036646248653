import { Star } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import icon_camera from "../../asset/Img/icon_camera.png";
import icon_text from "../../asset/Img/icon_text.png";
import UiNotice_Style from "../UiNotice/UiNotice_Style";

import PdfUploadModal from "../../pages/StudyBookDetail/components/PdfUploadModal";
import styles from "./style.module.scss";

const UiQuestion = ({
  userInfo,
  setIsText,
  apiPro_img,
  bookData,
  setQnaData,
  // setAddSearch,
  setFileObj,
  setHiddenUrl,
  linkData = {},
  isBook = true,
  isTeacher,
  isComplete,
  data,
  qnaCount = {},
  isSubs = false,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  // useEffect(()=>{
  //     console.log("QQQ ",qnaCount)
  // },[qnaCount])

  const [isLoading, setIsLoading] = useState(false);

  let Qstyle = {
    box: {
      width: "152px",
      height: "30px",
      padding: "10px 5px",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      borderRadius: "5px",
      border: "1px solid #EEEEEE",
    },
    box_icon: {
      width: "24px",
      height: "24px",
    },
    number_box: {
      // width:"25px",
      height: "15px",
      backgroundColor: "#EFD9B4",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 400,
      // padding : "1.5px 0px",
      padding: "2px 7px",
    },
  };

  // const apiPro_img = async (e) => {
  //     try {
  //         setQnaData({})
  //         setAddSearch({
  //             bid : params["id"]
  //         })
  //         setFileObj({
  //             name : ``,
  //             file : e
  //         })
  //         setHiddenUrl(window.location.pathname)
  //         navigate(`/crop/${params["who"]}/se`)
  //     }
  //     catch(e){
  //         console.log("apiPro_img Error= >",e)
  //     }
  // }

  useEffect(() => {
    console.log("qnaCount111 : ", qnaCount, bookData);
  }, [qnaCount]);

  return (
    <div
      style={{
        ...UiNotice_Style.c,
        marginBottom: "20px",
        backgroundColor: "#F9FCFF",
      }}
    >
      <div style={UiNotice_Style.title_w}>해답 검색 및 질문목록</div>
      {params["id"] != 0 ? (
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: 400,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              ...Qstyle.box,
              marginRight: "2px",
            }}
          >
            <input
              id="picture_find"
              type="file"
              accept="image/*"
              style={{
                display: "none",
              }}
              onChange={apiPro_img}
            />
            <label
              htmlFor="picture_find"
              style={{
                display: "flex",
                // flexDirection:"column",
                alignItems: "center",
                // width : "24px",
                // height : "24px",
              }}
            >
              <img
                style={{
                  ...Qstyle.box_icon,
                  marginRight: "5px",
                }}
                src={icon_camera}
              />
              이미지로 해답 검색
            </label>
          </div>
          <div
            style={{
              ...Qstyle.box,
              marginLeft: "2px",
            }}
            onClick={() => {
              setIsText(true);
            }}
          >
            <img style={Qstyle.box_icon} src={icon_text} />
            텍스트로 해답 검색
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          fontSize: "14px",
          fontWeight: 400,
          justifyContent: "space-between",
          marginTop: "7px",
        }}
      >
        <div
          style={{
            ...Qstyle.box,
            marginRight: "2px",
            justifyContent: "center",
          }}
          onClick={() => {
            if (isBook) {
              navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/de/book");
            } else {
              navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/de/lecture");
            }
          }}
        >
          <Star
            style={{
              color: "#FFDD29",
              fontSize: "26px",
            }}
          />
          <div
            style={{
              margin: "0px 5px",
            }}
          >
            모범 풀이
          </div>
          <div style={Qstyle.number_box}>{qnaCount["mobum"]}</div>
        </div>
        {params["who"] == "teacher" ? (
          <div
            style={{
              ...Qstyle.box,
              marginLeft: "2px",
            }}
            onClick={() => {
              if (isBook) {
                navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/ready/book");
              } else {
                navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/ready/lecture");
              }
            }}
          >
            모범 풀이 등록 대기
            <div style={Qstyle.number_box}>{qnaCount["ready"]}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          fontSize: "14px",
          fontWeight: 400,
          justifyContent: "space-between",
          marginTop: "7px",
        }}
      >
        <div
          style={{
            ...Qstyle.box,
            backgroundColor: "#F09294",
            marginLeft: "2px",
            color: "white",
          }}
          onClick={() => {
            if (isBook) {
              navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/proc/book");
            } else {
              navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/proc/lecture");
            }
          }}
        >
          진행중인 질문
          <div
            style={{
              ...Qstyle.number_box,
              color: "#F09294",
              backgroundColor: "white",
            }}
          >
            {qnaCount["live"]}
          </div>
        </div>
        <div
          style={{
            ...Qstyle.box,
            backgroundColor: "#EFD9B4",
            color: "white",
            marginLeft: "2px",
          }}
          onClick={() => {
            if (isBook) {
              navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/complete/book");
            } else {
              navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/complete/lecture");
            }
          }}
        >
          완료된 질문
        </div>
      </div>
      {/* {
            <div style={UiQuestion_Style.section_c}
                onClick={()=>{
                    if(isBook){
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/de/book")
                    }
                    else {
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/de/lecture")
                    }
                }}
            >
                <Form2 isStar={true} title="모범 풀이" desc='선생님이 직접 고른 모범 풀이 질문' />
                <Form data={qnaCount["mobum"]} />
            </div>
            } */}
      {/* {
            (isBook && params["who"] == "teacher") &&
            <div style={UiQuestion_Style.section_c}
                onClick={()=>{
                    if(isBook){
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/ready/book")
                    }
                }}
            >
                <Form2 title="모범 풀이 등록 대기" desc='선생님이 직접 올리신 모범 풀이 등록 대기중인 질문 목록' />
                <Form data={qnaCount["ready"]}/>
            </div>
            } */}
      {/* {
            <div style={UiQuestion_Style.section_c}
                onClick={()=>{
                    if(isBook){
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/proc/book")
                    }
                    else {
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/proc/lecture")
                    }
                }}
           >
                <Form2 title="진행중인 질문" desc='답변 진행중인 질문 목록' />
                <Form data={qnaCount["live"]} />
            </div>
            } */}
      {/* {
            isSubs ?
            <div style={UiQuestion_Style.section_c}
                onClick={()=>{
                    if(isBook){
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/proc/book")
                    }
                    else {
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/proc/lecture")
                    }
                }}
           >
                <Form2 title="구독자 질문" desc='구독자분들의 질문 목록' />
                <Form data={qnaCount["live"]} />
            </div>
            :
            <></>
            } */}
      {/* {
            isSubs &&
            <div style={UiQuestion_Style.section_c}
                onClick={()=>{
                    if(isBook){
                        navigate("/explainmodel/"+params["who"]+"/"+`${params["id"]}`+`/subs&${linkData["type"]}/book`)
                    }
                    // else {
                    //     navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/complete/lecture")
                    // }
                }}
           >
                <Form2 title="구독 질문" desc='구독자들의 질문 목록' />
                <Form data={qnaCount["subs"]} />
            </div>
            } */}
      {/* {
            isComplete &&
            <div style={UiQuestion_Style.section_c}
                onClick={()=>{
                    // navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/complete")
                    if(isBook){
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/complete/book")
                    }
                    else {
                        navigate("/explainmodel/"+params["who"]+"/"+params["id"]+"/complete/lecture")
                    }
                }}
           >
                <Form2 title="완료된 질문" desc='답변 완료된 질문 목록' />
                <Form data={qnaCount["qna"]} />
            </div>
            } */}
      {[4, 5].includes(linkData.type) && [0, 3].includes(bookData?.book_type) && (
        <div className={styles.QuestionRegisterButtonWrapper}>
          {/*<div*/}
          {/*    className={styles.PdfButton}*/}
          {/*>*/}
          {/*    <input*/}
          {/*        id="pdf-upload"*/}
          {/*        type="file"*/}
          {/*        accept=".pdf"*/}
          {/*        style={{display: "none"}}*/}
          {/*        onChange={async e => {*/}
          {/*            if (isFileUploading) {*/}
          {/*                alert('다운로드 대기중입니다')*/}
          {/*            } else if (e.target.files.length > 1) {*/}
          {/*                alert('파일을 하나만 선택해주세요')*/}
          {/*            } else if (e.target.files.length === 0) {*/}
          {/*                setIsFileUploading(false)*/}
          {/*                return*/}
          {/*            }*/}
          {/*            const pdfFile = e.target.files[0]*/}
          {/*            setIsFileUploading(true)*/}
          {/*            await apiFn.uploadFile(`pdf/${pdfFile.name}`, pdfFile)*/}

          {/*            const timer = setInterval(async () => {*/}
          {/*                try {*/}
          {/*                    const res = await fetch(`${constants.apiUrl}/file/lumberjack/?name=${pdfFile.name.split('.')[0]}`)*/}
          {/*                    if (res.status === 200) {*/}
          {/*                        clearInterval(timer)*/}

          {/*                        const downloadUrl = await res.json()*/}
          {/*                        // const urls = await res.json()*/}
          {/*                        // await Promise.all(urls.map(async url => {*/}
          {/*                        //     // console.log(url)*/}
          {/*                        //     // await fetch(`http://native_download?filename=${pdfFile.name.split('.')[0]}?filepath=${url}`)*/}
          {/*                        //     window.open(`http://native_download?filename=${pdfFile.name.split('.')[0]}?filepath=${url}`)*/}
          {/*                        // }))*/}
          {/*                        window.location.href = `http://native_download?filename=${pdfFile.name.split('.')[0]}.zip?filepath=pdf/${pdfFile.name.split('.')[0]}.zip`*/}
          {/*                        setIsFileUploading(false)*/}
          {/*                    }*/}
          {/*                } catch (e) {*/}
          {/*                    const isRetry = confirm('다운로드에 실패했습니다\n다시 시도하시겠습니까?')*/}
          {/*                    if(!isRetry) {*/}
          {/*                        clearInterval(timer)*/}
          {/*                        setIsFileUploading(false)*/}
          {/*                    }*/}
          {/*                    console.error(e)*/}
          {/*                }*/}
          {/*            }, 5000)*/}

          {/*            // Show modal, set loading*/}
          {/*            // gcp upload*/}
          {/*            // wait until get image urls (polling)*/}
          {/*            // then download to folder*/}

          {/*        }}*/}
          {/*    />*/}
          {/*    <label htmlFor="pdf-upload">*/}
          {/*        {*/}
          {/*            isFileUploading ?*/}
          {/*                <div>*/}
          {/*                    <CircularProgress style={{*/}
          {/*                        color: style.common.t_color*/}
          {/*                    }}/> 파일 처리 중...*/}
          {/*                </div> :*/}
          {/*                'PDF를 질문 이미지로 변환'*/}
          {/*        }*/}
          {/*    </label>*/}
          {/*</div>*/}
          <button onClick={() => navigate(`/mobumregi/teacher/${linkData.bid}/de/de`)}>
            모범 질문 등록
          </button>
        </div>
      )}

      {[4, 5].includes(linkData.type) && [1, 2].includes(bookData?.book_type) && <PdfButtonArea />}

      {
        //     onSubmit={async (pdfFile, title, type, isSolution, startPage, endPage) => {
        //         const BookType = {"material": 1, "note": 2}
        //         try {
        //             const book_type = BookType[type]
        //             setIsLoading(true)
        //             const uploadPath = `pdf/${userInfo.id}_${(new Date()).getTime()}_${pdfFile.name}`
        //             const pdfResponse = await fetch(`${constants.apiUrl}/pdf`, {
        //                 method: "POST",
        //                 headers: {
        //                     'Content-Type': 'application/json'
        //                 },
        //                 body: JSON.stringify({
        //                     mid: userInfo.id,
        //                     bid: params.id,
        //                     path: uploadPath,
        //                     // title,
        //                     name: pdfFile.name.replace('.pdf', '').replace('.PDF', ''),
        //                     isSolution: isSolution.toLowerCase() === "true",
        //                     book_type,
        //                     startPage,
        //                     endPage
        //                 })
        //             })
        //             if (pdfResponse.status !== 200) {
        //                 alert('PDF 정보 등록 중 오류가 발생했습니다')
        //                 setIsLoading(false)
        //                 return
        //             }
        //             const pdf = await pdfResponse.json()
        //             /**
        //              * Commented out by Gordon Ahn.
        //              */
        //             //await apiFn.uploadFile(uploadPath, pdfFile)
        //             const bucketName = process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_MODE === 'live' ? 'hiqsum-pdf' : 'hiqsum-test-pdf';
        //             await apiFn.uploadFileToStorage(bucketName, uploadPath, pdfFile);
        //             const interval = setInterval(
        //                 async () => {
        //                     const res = await fetch(`${constants.apiUrl}/pdf/${pdf.id}`)
        //                     if (res.status !== 200) {
        //                         return
        //                     }
        //                     // const {is_converted} = await res.json()
        //                     // if (is_converted === 0 || !is_converted)
        //                     //     return
        //                     clearInterval(interval)
        //                     setIsFileUploading(false)
        //                     //alert('추가 완료됐습니다.')
        //                     setIsLoading(false)
        //                 }, 3000)
        //         } catch (e) {
        //             console.error(e)
        //             alert('PDF에서 교재 생성에 실패 했습니다')
        //             setIsLoading(false)
        //         }
        //     }}
        //     onCancel={() => {
        //         setIsFileUploading(false)
        //     }}
      }
    </div>
  );
};

const PdfButtonArea = () => {
  const [isSolution, setIsSolution] = useState(false);
  const [showPdfUploadModal, setShowPdfUploadModal] = useState(false);
  const { id } = useParams();
  return (
    <>
      <div className={styles.QuestionRegisterButtonWrapper}>
        <button
          className={styles.pdfButton}
          onClick={() => {
            setShowPdfUploadModal(true);
            setIsSolution(false);
          }}
        >
          PDF 문제추가
        </button>
        <button
          className={styles.pdfButtonSolution}
          onClick={() => {
            setShowPdfUploadModal(true);
            setIsSolution(true);
          }}
        >
          PDF 해설추가
        </button>
      </div>
      {showPdfUploadModal && (
        <PdfUploadModal
          open={showPdfUploadModal}
          isSolution={isSolution}
          bookId={id}
          onClose={() => setShowPdfUploadModal(false)}
        />
      )}
    </>
  );
};

export default UiQuestion;
