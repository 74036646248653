import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useClassDetailInfo } from "api/classDetail";
import constants from "asset/constants";
import DefaultTeacherImage from "asset/Img/defaultpic_teacher.png";
import util from "asset/util";
import { Accordion, AccordionDetails, AccordionSummary } from "components/atoms/Accordion";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

const TeacherInfo = () => {
  const params = useParams();
  const { data } = useClassDetailInfo({
    boardId: params["id"],
  });

  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const teacherData = useMemo(() => videoData?.user, [videoData]);

  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Container>
      <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
        <AccordionSummary>
          <SpaceBetween>
            <LeftContainer>
              <TeacherPicture
                src={
                  videoData.mid &&
                  `${constants.s3_url}/teacher/${videoData.mid.normalize(
                    "NFD"
                  )}.jpg?${util.getRandomInt(1000, 9999)}`
                }
                onError={(event) => {
                  event.currentTarget.src = DefaultTeacherImage;
                }}
              />
              <TeacherNameFont>{teacherData?.name} 선생님</TeacherNameFont>
            </LeftContainer>
            <MoreButton>
              {expanded ? (
                <>
                  <div>접기</div>
                  <ExpandLessRoundedIcon />
                </>
              ) : (
                <>
                  <div>강사소개</div>
                  <ExpandMoreRoundedIcon />
                </>
              )}
            </MoreButton>
          </SpaceBetween>
        </AccordionSummary>
        <AccordionDetails>
          <HairLine />
          <TeacherIntroduce dangerouslySetInnerHTML={{ __html: teacherData?.intro }} />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TeacherPicture = styled.img`
  border-radius: 40px;
  width: 40px;
  height: 40px;
`;

const TeacherNameFont = styled.div`
  color: #292929;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

const MoreButton = styled(TeacherNameFont)`
  display: flex;
  width: 86px;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 99px;
  border: 1px solid #ffa723;
  color: #ffa723;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  & > div {
    flex: 1;
    text-align: center;
  }
`;

const TeacherIntroduce = styled.span`
  color: #292929;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const HairLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: #cbcbcb;
  margin: 16px;
`;
export default TeacherInfo;
