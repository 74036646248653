import { useQuery } from "@tanstack/react-query";
import { WhoType } from "../types/enum";
import { axiosInstance } from "./common";

export type questionSearchRequest = {
  userId?: string;
  userType: WhoType;
  bookTypes?: Array<number>;
  option?: questionSearchPropsOption;
  searchType?: questionSearchPropsSearchType | string;
  limit?: number;
  searchData?: string | number;
  myQuestion?: boolean;
  bookId?: number | string;
  isSolution?: boolean;
};
export enum questionSearchOption {
  mobum = "exemplary",
  qna = "solved",
  live = "inProgress",
  all = "all",
}

type questionSearchPropsOption = "exemplary" | "solved" | "inProgress" | "all";
export type questionSearchPropsSearchType = "question" | "image" | "text";

export type questionSearchResponseData = {
  score: number;
  id: number;
  ans_date: number;
  mobum_date: number;
  title: string;
  sid: null | string;
  tid: string;
  mosaic: string;
  img: string;
  HIQDB_book_links: questionBookLinks[];
  HIQDB_online_qna_msgs: questionAnswerMessage[];
};

export type questionBookLinks = {
  bid: number;
  bid_HIQDB_book: {
    id: number;
    name: string;
    book_type: number;
  };
};
export type questionAnswerMessage = {
  wtype: number;
  content: string;
  ctype: number;
};
export const useQuestionSearch = ({
  userId,
  userType,
  bookTypes,
  option,
  searchType,
  limit,
  searchData,
  myQuestion,
  bookId,
  isSolution,
}: questionSearchRequest) => {
  return useQuery({
    queryKey: [
      "/search/question",
      {
        userId,
        userType,
        bookTypes,
        option,
        searchType,
        limit,
        searchData,
        myQuestion,
        bookId,
        isSolution,
      },
    ],
    queryFn: async () => {
      return axiosInstance.post<questionSearchResponseData[]>(`/search/question`, {
        userId,
        userType,
        bookTypes,
        option,
        searchType,
        limit,
        searchData,
        myQuestion,
        bookId,
        isSolution,
      });
    },
    enabled: !!userId && !!searchData && limit != 0,
    select: (data) => data.data,
    suspense: false,
  });
};

export const useGetOcrData = (imageBase64: string, isSearch: boolean) => {
  return useQuery({
    queryKey: ["/ocr/image", { imageBase64 }],
    queryFn: async () => {
      return axiosInstance.post<{ result: string }>(`/ocr/image`, {
        encodedImage: imageBase64,
      });
    },
    enabled: !!imageBase64 && isSearch,
    select: (data) => data.data,
    suspense: false,
  });
};
