import apiFn from "asset/apiClass";
import constants from "asset/constants";
import { getPw, getUser } from "asset/storage";
import store from "asset/store";
import axios, { AxiosResponse } from "axios";
import { GraphQLClient } from "graphql-request";
const config = {
  baseURL: constants.apiUrl,
};

export const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(async (config) => {
  console.log("axios", config);

  const userInfo = await getUser();
  const jwt = userInfo?.jwt;
  if (jwt) {
    config.headers["Authorization"] = `Bearer ${jwt}`;
  }
  try {
  } catch (error) {
    return Promise.reject(error);
  }
  return config;
});

const responseSuccessInterceptor = async (response: AxiosResponse<any>) => {
  return response;
};

const responseErrorInterceptor = async (error: any) => {
  // 인증 에러 처리
  const isUnauthorized = error?.response?.status === 401 || error?.response?.status === 403;

  if (isUnauthorized) {
    const { id, provider } = getUser() || {};
    const pw = getPw();
    if (id && pw) {
      let isOk = window.confirm(constants.confirm.cf_24);
      if (isOk) {
        const { provider } = getUser() || {};
        // 로그아웃 -> 홈 화면 이동
        if (provider == "naver") {
          await apiFn.logout({
            state: {
              provider: "naver",
            },
          });
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("pw");
          window.localStorage.removeItem("com.naver.nid.access_token");
          window.localStorage.removeItem("com.naver.nid.oauth.state_token");
          store.set("user", {});
          window.location.replace("/");
        } else {
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("pw");
          store.set("user", {});
          window.location.replace("/");
        }
      }
    }
  }
  console.log("isUnauthorized", isUnauthorized);

  console.error(
    error?.response?.config?.method?.toUpperCase(),
    error?.response?.config?.url,
    error?.response?.status,
    error?.response
  );

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export const gqlClient = new GraphQLClient(`${constants.apiUrl}/graphql`, {});
