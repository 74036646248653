import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ApolloClientProvider from "./ApolloClientProvider";
import ConfirmProvider from "./ConfirmProvider";
import ErrorBoundary from "./ErrorBoundary";
import ReactQueryClientProvider from "./ReactQueryClientProvider";
import ReduxProvider from "./ReduxProvider";
import RootSuspense from "./RootSuspense";
import RootThemeProvider from "./RootThemeProvider";
import ToastProvider from "./ToastProvider";

const Providers = ({ children }) => {
  return (
    <ReduxProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <ReactQueryClientProvider>
              <ApolloClientProvider>
                <ToastProvider>
                  <RootSuspense>
                    <RootThemeProvider>
                      <ConfirmProvider>{children}</ConfirmProvider>
                    </RootThemeProvider>
                  </RootSuspense>
                </ToastProvider>
              </ApolloClientProvider>
            </ReactQueryClientProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </HelmetProvider>
    </ReduxProvider>
  );
};

export default Providers;
