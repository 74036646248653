import React from "react";
import styled from "styled-components";
import Header from "./components/Header";
import ImgViewModal from "./components/ImgViewModal";
import PdfAllViewModal from "./components/PdfAllViewModal";
import PdfViewContextProvider from "./components/PdfViewContext";
import Slider from "./components/Slider";

const PdfView = () => {
  return (
    <PdfViewContextProvider>
      <Container>
        <Header />
        <Slider />
        <PdfAllViewModal />
        <ImgViewModal />
      </Container>
    </PdfViewContextProvider>
  );
};

export default PdfView;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  height: auto;
  width: 100%;
  max-width: 1500px;
  padding: 0 20px;
  flex-direction: column;
  box-sizing: border-box;
  background-color: rgb(251, 252, 254);
  margin: 0 auto;
`;
