import React, {useContext, useMemo} from "react"

import {useLocation, useParams} from "react-router";
import styled from "styled-components";
import {Close, Search} from "@material-ui/icons";
import {PdfType, usePdfList} from "../../../../api/pdfView";
import {createPortal} from "react-dom";
import {PdfViewActionsContextState, PdfViewContext} from "../PdfViewContext";
import ImgItem from "./ImgItem";

const PdfAllViewModal = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const {pdfAllViewModalShow, changeModalState, currentSlide} = useContext<PdfViewActionsContextState>(PdfViewContext);

  const {data} = usePdfList({
    bookId: params.bookId,
    isAnswer: searchParams.get('type') === 'answer' ? PdfType.answer : PdfType.question
  });
  const pdfImgList = useMemo(() => {
    return data?.qnaList
  }, [data])

  if (!pdfAllViewModalShow) {
    return null
  }

  return createPortal(
    <PdfViewAllItemsContainer>
      <PdfViewAllItemHeader>
        <div>
          {currentSlide + 1}/{pdfImgList.length}
        </div>
        <HeaderRightBox>
          {/*<Button>*/}
          {/*  편집*/}
          {/*</Button>*/}
          {/*<Search/>*/}
          <Button onClick={() => changeModalState(false)}>
            <Close/>
          </Button>
        </HeaderRightBox>
      </PdfViewAllItemHeader>
      <PdfViewAllItemContent>
        {
          pdfImgList.length > 0 && pdfImgList.map((item, index) => <ImgItem item={item} key={index} index={index} className ={currentSlide === index ? 'active': ''}/>)
        }
      </PdfViewAllItemContent>
    </PdfViewAllItemsContainer>,
    document.body
  )
}

export default PdfAllViewModal

const PdfViewAllItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f1f1f1;
  z-index: 3;
  padding: 16px;
  box-sizing: border-box;
`
const PdfViewAllItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
`
const HeaderRightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
const Button = styled.button`
  background: transparent;
  border: 0;
  font-size: 16px;
`
const PdfViewAllItemContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 10px;
  overflow: scroll;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  padding: 4px 4px;
`