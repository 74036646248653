import { ChatBubble } from "@material-ui/icons";
import { PaymentContext } from "asset/context";
import axios from "axios";
import SelectTicket from "pages/Payment/Entrance/SelectTicket";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import YoutubeLogo from "../../asset/Img/add_youtube.png";
import img_search from "../../asset/Img/btn_img_search.png";
import interact from "../../asset/Img/interact.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import util from "../../asset/util";
import Draggable from "../../common/Draggable";
import UiMessage from "../../uiComponents/UiMessage/UiMessage";
import UiMessage_Style from "../../uiComponents/UiMessage/UiMessage_Style";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import SelectTeacherModal from "./SelectTeacherModal";
import ToSAgreementModal from "./ToSAgreementModal";

const Message_Home = ({
  tcnt,
  setAddSearch,
  isSR_detail,
  setIsSR_detail,
  isDetail,
  setIsDetail,
  t_page,
  setT_page,
  setIsEdit,
  isEdit,
  isTeacher,
  userInfo,
  messageList,
  setMessageList,
  setFileObj,
  fileObj,
  apiPro_counts,
  setHiddenUrl,
  setIsText,
  setQnaData,
  scrollKey,
  setScrolllKey,
  message_page,
  setMessage_page,
  isLoading,
  s_value,
  setS_value,
  setIsLoading,
  lectureId,
  setLectureId,
  fnCheck,
  fnLongClick,
  apiPro_del,
  selectData,
  setSelectData,
  setSelectData_l,
  listStat,
  setListStat,
  isLectureMsg,
  messageList_lecture,
  setIsLectureMsg,
  setMessageList_lecture,
  init_message,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    paymentOpen,
    setPaymentOpen,
    isPaymentModalOpen,
    setPaymentModalOpen,
    isSelectTeacherModalOpen,
    setIsSelectTeacherModalOpen,
  } = useContext(PaymentContext);

  const classroomId = constants.classroomId;

  const [msgData, setMsgData] = useState({});
  const [myInfo, setMyInfo] = useState({});
  // 내 메세지 함 UI 표현 상태 값
  // ui 좌측 부터 a, b, c, d

  const [lectureList, setLectureList] = useState([]);
  const openTicketModal = useCallback(() => {
    if (paymentOpen !== "none") {
      setPaymentOpen("select");
      // 학원앱인 경우 결제 모달 띄우기
      if (classroomId) {
        setPaymentModalOpen(true);
        // 하이큐썸앱인 경우 수업 선택 페이지로 이동
      } else {
        navigate("/searchresult/student/student/p");
      }
    } else {
      // 학원앱인 경우 선생님 선택 모달 띄우기
      if (classroomId) {
        setIsSelectTeacherModalOpen(true);
      } else {
        // 하이큐썸앱인 경우 수업 선택 페이지로 이동
        navigate("/searchresult/student/student/p");
      }
    }
  }, [setPaymentOpen, paymentOpen]);

  // 수업 목록 호출 API
  const apiPro_read_lecture = async (id, sort) => {
    try {
      if (lectureId) return;
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          u_id: userInfo["id"]
            ? userInfo["id"]
            : JSON.parse(window.localStorage.getItem("user"))["id"],
          sort: `reg_date desc`,
          ref: [
            "HIQDB_member_class.cid|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_member_class.mid|HIQDB_class.id=HIQDB_member_class.cid",
            `B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`,
          ],
          where: {
            "HIQDB_member_class.mid": `='${userInfo["id"] ? userInfo["id"] : JSON.parse(window.localStorage.getItem("user"))["id"]}'`,
            "HIQDB_class.status": `='1'`,
            "HIQDB_member_class.status": `!='9'`,
            // 'HIQDB_class.status':`=!'9'`,
          },
          count: [
            "HIQDB_member_class.mid:cid:id|and countTable.type ='0' and countTable.status='1'|lcnt",
          ],
        },
      });

      let array = (data["list"] || []).reduce((a, v, i) => {
        return [
          ...a,
          {
            name: v["name"],
            value: v["id"],
          },
        ];
      }, []);

      setLectureList([{ name: "수업 전체", value: "all" }, ...array]);
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_read = async () => {
    try {
      // let data = await apiFn.comApi({
      //     state : {
      //         table : userInfo["type"] == 1 ? "student" : "teacher",
      //         key : `id:${params["id"]}`
      //     }
      // })
      // return data
      let data = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_user_credential.name": `= '${userInfo["id"]}'`,
          },
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
          // key : `id:${params["id"]}`
        },
      });

      // // console.log("1 chk data => ",data["list"])
      if (data["list"].length == 0) {
        data = await apiFn.comApi({
          state: {
            table: "user_credential",
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            where: {
              "HIQDB_user_credential.name": `= '${userInfo["id"]}'`,
            },
            ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
            count: [
              "HIQDB_online_qna.tid:tid:id|and countTable.mobum_date is not null |mobumCnt",
              "HIQDB_book_link.mid:mid:id|and countTable.type > 4 and countTable.bid != 0 and countTable.cid =0 and countTable.status !=9 |bookCnt",
            ],
            // key : `id:${params["id"]}`
          },
          // online_qna
          // 조건 : tid = 선생님 ID, mobum_date is not null

          // book_link
          // 조건 : mid = 선생님 ID, type >4, bid !=0, cid=0
        });
      }

      // // console.log("2 chk data => ",data["list"])

      setMyInfo(data["list"][0]);
      // // console.log(data["list"][0])
      // // console.log(data["list"][0])
      // // console.log(data["list"][0])
      // // console.log(data["list"][0])
      if (data["list"][0]["suupList"].length == 0 && messageList.length == 0) {
        setMsgData(false);
      } else {
        setMsgData(true);
      }
    } catch (e) {
      // console.log("?? ",e);
    }
  };

  const apiPro_img = async (e) => {
    try {
      console.log("non youtube search");
      window.localStorage.setItem("saveSearchPrevUrl", "/message/teacher/home");
      setQnaData({});
      setFileObj({
        name: ``,
        file: e,
      });
      setHiddenUrl(window.location.pathname);
      if (userInfo?.type === 2) {
        navigate(`/crop/${params["who"]}/searchImage`);
      } else {
        navigate(`/crop/${params["who"]}/se`);
      }
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  const apiPro_img_book_youtube = async (e) => {
    console.log("youtube search");
    e.stopPropagation();
    try {
      setQnaData({});
      setAddSearch({
        bid: "687",
      });
      setFileObj({
        name: ``,
        file: e,
      });
      setHiddenUrl(window.location.pathname);
      navigate(`/crop/${params["who"]}/se`);
    } catch (e) {
      console.log("apiPro_img Error= >", e);
    }
  };

  const fnlistStat = (list, value) => {
    try {
      // console.log("listStat : ",listStat)
      // console.log("value => ", value);
      // console.log("list => ", list);
      if (!value && !s_value) return;
      if (value == "all") {
        setIsLectureMsg(false);
      } else {
        let keyBox = list.reduce((a, v, i) => {
          return {
            ...a,
            [v["value"]]: v["name"],
          };
        }, {});

        // console.log("value : ", value)
        // console.log("s_value : ", s_value)

        let msgList = [];
        messageList.map((v, i) => {
          if (v["className"]) {
            if (s_value) {
              if (v["className"].indexOf(keyBox[s_value]) > -1) {
                msgList.push(v);
              }
            } else {
              if (v["className"].indexOf(keyBox[value]) > -1) {
                msgList.push(v);
              }
            }
          }
        });

        // console.log("   msgList : ",msgList)

        setIsLectureMsg(true);
        setS_value(value);
        setMessageList_lecture([...msgList]);
      }
      // return keyBox[value]
    } catch (e) {
      setIsLectureMsg(false);
      console.log("fnlistStat Error => ", e);
    }
  };

  const [isAgreedOnToS, setIsAgreedOnToS] = useState(true);
  const [notAgreeTosData, setNotAgreeTosData] = useState([]);
  const getToSAgreement = async () => {
    try {
      console.log(userInfo);
      let { data } = await axios.get(constants.apiUrl + "/user/tos", {
        params: { userId: userInfo.id },
      });
      const { tosData } = data;
      setIsAgreedOnToS(tosData.length === 0);
      setNotAgreeTosData(tosData);
    } catch (e) {
      console.log("Agree Error => ", e);
    }
  };

  useEffect(() => {
    setIsSR_detail(false);
    // if(isDetail){
    //     setIsDetail(false)
    //     return;
    // }
    let chk;
    if (window.localStorage.getItem("msg_home")) {
      chk = JSON.parse(window.localStorage.getItem("msg_home"));
    }

    // if(params["who"] == "student"){
    //     if(chk){
    //         setIsChk(true)
    //         apiPro_counts("", 1, false, true)
    //     }
    //     else {
    //         apiPro_counts("", 1, false, false)
    //         setIsChk(false)
    //     }
    // }
    // else {
    //     // console.log("!!!!!!!!!")
    //     if(chk){
    //         // console.log("!!!!!!!!! @ @ @@  @ @")
    //         setIsChk(true)
    //         apiPro_counts("Y",1,false,false,window.localStorage.getItem("msg_home_lc") ? window.localStorage.getItem("msg_home_lc") : "");
    //     }
    //     else {
    //         // console.log("!!!!!!!!! # #  # # # ##  ## ")
    //         apiPro_counts("",null,false,false,window.localStorage.getItem("msg_home_lc") ? window.localStorage.getItem("msg_home_lc") : "");
    //         setIsChk(false)
    //     }
    // }

    let savedListStat = "All";

    if (window.localStorage.getItem("msg_home_section")) {
      // console.log("@@@@@@@@@@@@@@ ",window.localStorage.getItem("msg_home_section"));
      setListStat(window.localStorage.getItem("msg_home_section"));
      savedListStat = window.localStorage.getItem("msg_home_section");
    }
    /* 0916 메세지 개편 때 하는 작업  */
    apiPro_read_lecture();
    //////////////////////////////
    apiPro_read();
    if (userInfo.id) getToSAgreement();
    try {
      setHiddenUrl("");
      if (messageList.length > 0) return;
      // console.log("########################")
      // let chk;
      // if(window.localStorage.getItem("msg_home")){
      //     chk = JSON.parse(window.localStorage.getItem("msg_home"))
      // }
      let page = message_page;
      init_message(true, savedListStat);
    } catch (e) {}
  }, [userInfo]);

  // useEffect(()=>{
  //     console.log("HISTORY : ",history)
  // },[history])

  useEffect(() => {
    console.log("messageList => ", messageList);
    // // console.log("userInfo => ",userInfo)
    // if(messageList.length == 0 &&){

    // }
    fnlistStat(lectureList);
  }, [messageList]);

  return (
    <>
      {!msgData || msgData.length <= 0 ? (
        <div style={UiMessage_Style.noData_w}>
          <img src={interact} style={UiMessage_Style.noData_i} />
          <div style={UiMessage_Style.text_w}>
            <div>진행중인 대화가 없습니다.</div>
            <div>
              {params["who"] == "student"
                ? "수업에 등록하고 선생님에게 모르는 문제를 물어보세요."
                : "1개 이상의 수업을 개설해야 학생들이 수업 신청을 할 수 있어요."}
            </div>
          </div>
          <div style={UiMessage_Style.textBtn_w}>
            <UiTextBtn
              isTeacher={params["who"] == "teacher" ? true : false}
              text={params["who"] == "student" ? "수업 등록하기" : "수업 개설하기 영상"}
              fnClick={() => {
                if (params["who"] == "teacher") {
                  if (util.chk_platform()) {
                    window.open("https://youtu.be/xsh0sjdaHwI");
                  } else if (!util.chk_platform()) {
                    window.ReactNativeWebView.postMessage("Link:https://youtu.be/xsh0sjdaHwI");
                  }

                  // if(myInfo["hakwonList"].length == 0){
                  //     alert("가입하신 클래스룸이 없습니다.")
                  // }
                  // else {
                  //     // console.log(myInfo["hakwonList"])
                  //     let p_array = []
                  //     myInfo["hakwonList"].map((v,i)=>{
                  //         if(v["type"] == 5){
                  //             p_array.push(v)
                  //         }
                  //     })
                  //     // console.log("p_array => ",p_array)
                  //     if(p_array.length > 0){
                  //         navigate(`/lectureregi/teacher/${p_array[0]["id"]}`)
                  //     }
                  //     else {
                  //         alert("가입하신 클래스룸에 대한 수업 개설 권한이 없습니다.")
                  //     }
                  // }
                  // /lectureregi/teacher/316
                } else {
                  navigate("/lecture");
                }
              }}
            />
            {params["who"] == "student" && (
              <UiTextBtn
                isTeacher={params["who"] == "teacher" ? true : false}
                text={params["who"] == "student" ? "내 선생님 목록 보기" : "내 학생 목록 보기"}
                fnClick={() => {
                  if (params["who"] == "teacher") {
                    navigate("/people/teacher/home");
                  } else {
                    navigate("/people/student/home");
                  }
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: style.common.borderColor2,
            position: "relative",
            display: "flex",
            // flexDirection:"column",
            // flexGrow:1,
            // flex:1,
            height: "100%",
          }}
          id="message_home"
        >
          <div
            style={{
              width: "100%",
              // display:"flex",
              // flexDirection:"column",
              // flexGrow:1,
              // flex:1,
            }}
          >
            <div
              style={{
                width: "100%",
                // flexDirection:"column",
                // flexGrow:1,
                // flex:1,
                // marginTop:"5px",
                // padding : '0px 0px',
              }}
            >
              <UiMessage
                tcnt={tcnt}
                listStat={listStat}
                isDetail={isDetail}
                setIsDetail={setIsDetail}
                isHide={listStat === "d"}
                isEdit={isEdit}
                fnLongClick={fnLongClick}
                fnCheck={fnCheck}
                selectData={selectData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userInfo={userInfo}
                messageList={messageList}
                setMessageList={setMessageList}
                message_page={message_page}
                setMessage_page={setMessage_page}
                scrollKey={scrollKey}
                setScrolllKey={setScrolllKey}
                key={"message_home"}
                apiPro_counts={apiPro_counts}
                data={isLectureMsg ? messageList_lecture : messageList}
                isTeacher={isTeacher}
                fnClick={(d) => {
                  // console.log("message_page : ",message_page)
                  console.log(d);
                  // return;
                  setIsDetail(true);
                  setT_page(message_page);
                  setScrolllKey(d["id"]);
                  if (!d["sid"]) {
                    navigate(
                      "/talk/" +
                        params["who"] +
                        "/" +
                        d["id"] +
                        "/" +
                        (d["qid"] ?? "0") +
                        "/null/" +
                        (d.qid_HIQDB_online_qna?.tid ?? d.tid)
                    );
                  } else {
                    navigate(
                      "/talk/" +
                        params["who"] +
                        "/" +
                        d["id"] +
                        "/" +
                        (d["qid"] ?? "0") +
                        "/" +
                        d.sid +
                        "/" +
                        (d.qid_HIQDB_online_qna?.tid ?? d.tid)
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div style={{ height: 0, width: 0, position: "absolute", bottom: 0, top: "100%" }}>
        {isTeacher ? (
          <Draggable>
            <div
              style={{
                width: 72,
                height: 72,
                translate: "calc(375%) -150px",
                // borderRadius : 50,
                // height:"52px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // boxShadow: "1px 1px 2px 2px rgba(90 ,90 ,90 , 0.2)",
                // backgroundColor : isTeacher ? style.common.t_color : style.common.s_color
              }}
            >
              <input
                id="picture_find"
                type="file"
                accept="image/*"
                style={{
                  display: "none",
                }}
                onChange={apiPro_img}
              />
              <label
                htmlFor="picture_find"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={img_search}
                  style={{
                    width: 72,
                    height: 72,
                  }}
                />
              </label>
            </div>
          </Draggable>
        ) : (
          <Draggable>
            <button
              onClick={() => {
                openTicketModal();
              }}
              style={{
                border: "none",
                width: 72,
                height: 72,
                translate: "calc(375%) -150px",
                borderRadius: 12,
                // height:"52px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // boxShadow: "1px 1px 2px 2px rgba(90 ,90 ,90 , 0.2)",
                backgroundColor: style.common.s_color,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  color: "white",
                }}
              >
                <ChatBubble />
                <div style={{ fontSize: 12, fontWeight: 500, marginTop: 6 }}>질문하기</div>
              </div>
            </button>
          </Draggable>
        )}
        <Draggable>
          <div
            style={{
              width: 72,
              height: 72,
              translate: "calc(375%) -300px",
              // borderRadius : 50,
              // height:"52px",
              // backgroundColor: "#487FBA",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // boxShadow: "1px 1px 2px 2px rgba(90 ,90 ,90 , 0.2)",
              // backgroundColor : isTeacher ? style.common.t_color : style.common.s_color
            }}
          >
            <input
              id="youtube_find"
              type="file"
              accept="image/*"
              style={{
                display: "none",
              }}
              onChange={apiPro_img_book_youtube}
            />
            <label
              htmlFor="youtube_find"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: 72,
                height: 72,
                backgroundColor: style.common.t_color,
                borderRadius: 12,
              }}
            >
              <img src={YoutubeLogo} width={36} height={36} />
              <div style={{ fontSize: 12, color: "white", fontWeight: 400, marginTop: 6 }}>
                기출검색
              </div>
            </label>
          </div>
        </Draggable>
      </div>
      {/* {
        <div style={{
            position : "absolute",
            bottom:10,
            right: "46%",
            // width : "52px",
            // borderRadius : 50,
            // height:"52px",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            // boxShadow: "1px 1px 2px 2px rgba(90 ,90 ,90 , 0.2)",
            // backgroundColor : isTeacher ? style.common.t_color : style.common.s_color
        }}
            onClick={()=>{
                document.getElementsByClassName("infinite-scroll-component")[0].children[0].scrollIntoView({
                    block:'start',
                    inline:"start",
                })
                setTimeout(() => {
                    document.getElementById("message_home").scrollIntoView({
                        behavior : "smooth",
                        block:'start',
                        inline:"start",
                    })
                }, (100));
            }}
        >
            <ArrowCircleUp
                style={{
                    color : "white",
                    fontSize : "36px",
                    color : isTeacher ? style.common.t_color : style.common.s_color,
                    // backgroundColor:"white",
                }}
            />
        </div>
        } */}
      {!isAgreedOnToS && (
        <ToSAgreementModal
          userInfo={userInfo}
          notAgreeTosData={notAgreeTosData}
          onAgree={() => {
            setIsAgreedOnToS(true);
          }}
        />
      )}
      {!isTeacher && isSelectTeacherModalOpen && (
        <SelectTeacherModal
          onSubmit={(e, tid) => {
            if (!tid) {
              alert("선생님을 선택해주세요");
              return;
            }
            setQnaData({ tid });
            setIsSelectTeacherModalOpen(false);
            setHiddenUrl(window.location.pathname);
            // 파일을 고르면 크롭 화면으로 이동
            if (e.target.files.length === 1) {
              setFileObj({
                name: ``,
                file: e,
              });
              navigate(`/crop/student/qnaRegi`);
            } else {
              setFileObj(e.target.files);
              navigate("/searchresult/student/student/b");
            }
          }}
          onCancel={() => {
            setIsSelectTeacherModalOpen(false);
            setFileObj({});
            setHiddenUrl("");
          }}
        />
      )}
      {!isTeacher && isPaymentModalOpen && <SelectTicket />}
    </>
  );
};

export default Message_Home;
