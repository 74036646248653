import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Avatar } from "@mui/material";
import { PaymentContext } from "asset/context";
import constants from "../../../asset/constants";
import MenuItem from "../../../components/MenuItem";
import { Frame } from "../../page_index";
import MyInfoTicket from "../Myinfo_ticket";
import styles from "./style.module.scss";

const MenuItemView = ({ onClick, text }) => (
  <MenuItem onClick={onClick}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {text}
    </div>
  </MenuItem>
);

const Myinfo_Main = ({ userInfo, setIsPremium, isPremium }) => {
  const navigate = useNavigate();

  const [academyData, setAcademyData] = useState([]);
  const [classRoomName, setClassRoomName] = useState(null);

  const getMyInfo = async () => {
    setIsPremium(await (await fetch(`${constants.apiUrl}/user/${userInfo.id}/premium`)).json());
    if (userInfo.type === 2)
      setClassRoomName(
        (await (await fetch(`${constants.apiUrl}/user/${userInfo.id}/classroom`)).json())?.name
      );
  };

  const apiPro_read = async () => {
    try {
      let add_state = {};
      if (userInfo.type === 2) {
        add_state["tid"] = userInfo["id"];
      }

      let data = await apiFn.comApi({
        state: {
          ...add_state,
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_member_class.mid": `='${userInfo["id"]}'`,
            "HIQDB_member_class.cid": `='${constants.classroomId}'`,
            "HIQDB_member_class.status": `!='9'`,
          },
        },
      });

      // console.log("data => ",data)

      let array = [];
      let s_array = [];
      if (userInfo.type === 2) {
        data["list"].map((v) => {
          // // console.log(v)
          if (v["status"] != 2) {
            array.push(v);
          }
        });
        setAcademyData([...array]);
      } else {
        let reduceArray = data["list"].reduce((a, v, i) => {
          return [...a, v["pid"]];
        }, []);

        // console.log("reduceArray => ",reduceArray);
        let data2 = await apiFn.comApi({
          state: {
            table: "class",
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            where: {
              "HIQDB_class.id": `in (${reduceArray})`,
              "HIQDB_class.status": `!='9'`,
            },
          },
        });

        // // console.log("@@ ",data2);
        setAcademyData([...data2["list"]]);
      }
    } catch (e) {}
  };

  const handlePremium = async () => {
    console.log("premium", isPremium);
    try {
      if (isPremium) {
        // Process cancel

        const isPremium = await (
          await fetch(`${constants.apiUrl}/user/${userInfo.id}/premium`, {
            method: "DELETE",
          })
        ).json();
        setIsPremium(isPremium);
      } else {
        // check if premium user,
        const isPremium = await (
          await fetch(`${constants.apiUrl}/user/${userInfo.id}/premium`, {
            method: "POST",
          })
        ).json();
        // else make them pay
        setIsPremium(isPremium);
      }
    } catch (e) {
    } finally {
    }
  };

  useEffect(() => {
    constants.isAcademy && apiPro_read();
    getMyInfo();
  }, [constants.isAcademy]);

  const { setPaymentModalOpen, setPaymentOpen, paymentOpen } = useContext(PaymentContext);

  const openTicketModal = useCallback(() => {
    setPaymentOpen("purchase");
    setPaymentModalOpen(true);
  }, [setPaymentModalOpen, setPaymentOpen]);

  return (
    <Frame
      title="더 보기"
      isPremium={isPremium}
      userInfo={userInfo}
      isSearch={false}
      type={2}
      isBack={false}
    >
      <div className={styles.MyInfoMainContainer}>
        <MenuItem
          onClick={() => {
            navigate(`/myinfo/${userInfo.type === 1 ? "student" : "teacher"}/${userInfo.id}`);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Avatar
              src={
                constants.s3_url +
                `/${userInfo.type === 1 ? "student" : "teacher"}/${userInfo.id}.jpg`
              }
              style={{ display: "inline-block", marginRight: 10 }}
            />
            <span style={{ fontWeight: 900 }}>{userInfo.name}</span>&nbsp;&nbsp;
            <span>{userInfo.type === 1 ? "학생" : "선생님"}</span>
            {classRoomName && (
              <span style={{ color: "#afafaf", fontSize: 12 }}>&nbsp;|&nbsp;{classRoomName}</span>
            )}
          </div>
        </MenuItem>
        {userInfo.type === 2 && (
          <MenuItem
            onClick={() => {
              navigate("/myinfo/question");
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              내 질문 및 답변 관리
            </div>
          </MenuItem>
        )}
        {(constants.isAcademy && userInfo.id === constants.ownerId) ||
          academyData[0]?.type === 5 ||
          (!constants.isAcademy && userInfo.type === 2 && (
            <MenuItem
              onClick={() => {
                navigate("/academy/teacher/academy");
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                내 학원 관리
              </div>
            </MenuItem>
          ))}
        <MenuItem
          onClick={() => {
            const location =
              userInfo.type === 1
                ? "https://blog.naver.com/PostList.naver?blogId=hiqsum&from=postList&categoryNo=7"
                : "https://blog.naver.com/PostList.naver?blogId=hiqsum&from=postList&categoryNo=6";
            if (window?.ReactNativeWebView?.postMessage) {
              window.ReactNativeWebView.postMessage("Link:" + location);
            } else {
              window.location.href = location;
            }
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            이용 안내
          </div>
        </MenuItem>
        {userInfo.type === 2 && (
          <MenuItem
            onClick={() => {
              if (window?.ReactNativeWebView?.postMessage) {
                window.ReactNativeWebView.postMessage(
                  "Link:https://blog.naver.com/hiqsum/223118841474"
                );
              } else {
                window.location.href = "https://blog.naver.com/hiqsum/223118841474";
              }
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              결제 안내
            </div>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            if (window?.ReactNativeWebView?.postMessage) {
              window.ReactNativeWebView.postMessage(
                "Link:https://blog.naver.com/hiqsum/223118843357"
              );
            } else {
              window.location.href = "https://blog.naver.com/hiqsum/223118843357";
            }
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            자주 묻는 질문
          </div>
        </MenuItem>
        {paymentOpen != "none" && (
          <>
            <MenuItemView
              onClick={() => {
                navigate(
                  `/myinfo/ticketlog/${userInfo.type === 1 ? "student" : "teacher"}/${userInfo.id}`
                );
              }}
              text={"질문권 사용내역"}
            />
            {userInfo.type === 1 && (
              <>
                <MenuItemView
                  onClick={() => {
                    navigate(`/myinfo/orders/${userInfo.id}`);
                  }}
                  text={"주문 내역"}
                />
                {/* <MenuItemView
                  onClick={() => {
                    navigate(`/myinfo/order-cancels/${userInfo.id}`);
                  }}
                  text={"취소 내역"}
                /> */}
                {/* // FIXME: 결제 기능 도입 시 질문권 구매하기 복귀 */}
                {/* <button className={styles.purchaseButton} onClick={() => openTicketModal()}>
                  <span>질문권 구매하기</span>
                  <ArrowRight />
                </button> */}
              </>
            )}
            <MyInfoTicket />
          </>
        )}
      </div>
    </Frame>
  );
};

export default Myinfo_Main;
