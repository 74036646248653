import React, {useContext} from "react"
import styled from "styled-components";
import constants from "../../../../../asset/constants";
import {PdfViewActionsContextState, PdfViewContext} from "../../PdfViewContext";

const ImgItem = ({item, index, className}) => {
  const imageSrc = `${constants.s3_url}/qna/${item?.qid_HIQDB_online_qna?.img}.jpg`;
  const {changeModalState, changeCurrentSlideIndex} = useContext<PdfViewActionsContextState>(PdfViewContext);

  return (
    <ImgItemContainer onClick={()=> {
      changeModalState(false)
      changeCurrentSlideIndex(index)
    }}>
      <Image src={imageSrc} className={className} loading={"lazy"}/>
      <IndexBox>
        {index + 1}
      </IndexBox>
    </ImgItemContainer>
  )
}

export default ImgItem

const ImgItemContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);

  &.active{
    border: 2px solid red;
    box-shadow: none;
  }
`
const IndexBox = styled.div`
  text-align: center;
`