import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, from, fromPromise } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getUser, getPw } from "asset/storage";
import store from "asset/store";
import constants, { AUTH_CODE } from "asset/constants";
import apiFn from 'asset/apiClass'

const authLink = setContext((_, { headers }) => {
  const userInfo = getUser();
  return {
    headers: {
      ...headers,
      "Authorization": userInfo?.jwt ? `Bearer ${userInfo.jwt}` : ''
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors) {
    // GraphQL 관련 에러 발생시 로깅
    graphQLErrors.forEach(({ message, path, extensions }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, extensions: ${JSON.stringify(extensions)} path:${path}`
      )
    );
    // GraphQL 관련 에러 중 에러코드에 따라 토큰 리프레시 or 재로그인 결정
    let shouldRefreshToken = graphQLErrors.filter(({ extensions }) => AUTH_CODE.EXPIRED_TOKEN == extensions?.code).length > 0;
    // 토큰이 없거나, 유효하지 않은 경우 -> 재로그인
    let shouldReLogin = graphQLErrors.filter(({ extensions }) => [AUTH_CODE.INVALID_TOKEN, AUTH_CODE.NO_TOKEN].includes('' + extensions?.code)).length > 0;
    // 나머지 권한 밖의 요청 등은 재로그인 처리하지 않음

    if (shouldRefreshToken) {
      const { id, provider } = (getUser() || {});
      const pw = getPw();
      if (id && pw) {
        // 로컬스토리지의 id, pw를 이용해서 재로그인 후 다시 GraphQL 요청 전송(forward)
        return fromPromise(
          store.axiosPost({
            cat: "hiq",
            cmd: "newSignIn",
            id,
            pw
          }).catch((e: any) => { return })
        )
          .filter((res: any) => !!res?.jwt)
          .flatMap((res: any) => {
            window.localStorage.setItem("user", JSON.stringify(res));
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                "Authorization": `Bearer ${res.jwt}`,
              },
            });
            return forward(operation);
          });
      }
    } else if (shouldReLogin) {
      // 로그아웃 후 재로그인하시겠습니까?
      let isOk = window.confirm(constants.confirm.cf_24);
      if (isOk) {
        const { provider } = (getUser() || {});
        // 로그아웃 -> 홈 화면 이동
        if (provider == "naver") {
          return fromPromise(apiFn.logout({
            state: {
              provider: "naver",
            },
          }))
            .flatMap((res: any) => {
              window.localStorage.removeItem("user");
              window.localStorage.removeItem("pw");
              window.localStorage.removeItem("com.naver.nid.access_token");
              window.localStorage.removeItem(
                "com.naver.nid.oauth.state_token"
              );
              store.set("user", {});
              window.location.replace("/");
              return res;
            })
        } else {
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("pw");
          store.set("user", {});
          window.location.replace("/");
        }
      }
    }
  }
  if (networkError) {
    window.confirm(constants.confirm.cf_25);
  }
});

const httpLink = new HttpLink({
  uri: `${constants.apiUrl}/graphql`,
});

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

import React from "react";

const ApolloClientProvider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
