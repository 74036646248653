import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import style from '../../../asset/style'
import Basic from '../../../common/frame/Basic'
import photopick_teacher from '../../../asset/Img/photopick_teacher.png'
import photopick_student from '../../../asset/Img/photopick_student.png'
import Register_Intro_style from './Register_Intro_Style';
import Register_One_style from './Register_One_style';
import RegiForm from './RegiForm';
import { UiInput_title } from '../../../uiComponents/UiInput/UiInput';
import UiBtn from '../../../uiComponents/UiBtn/UiBtn';
import { AddCircle, AddCircleOutline, Input } from '@material-ui/icons';
import Register_Four_style from './Register_Four_style';
import util from '../../../asset/util';
import apiFn from '../../../asset/apiClass';
import crypto from 'crypto-js'
import constants from '../../../asset/constants';

const Register_Four = ({
    userInfo,
    regiData,
    setRegiData,
    setFileObj
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [img,setImg] = useState();

    const apiPro_img = async (e) => {
        try {
            setFileObj({
                name : `/${params["who"]}/${userInfo["id"]}.jpg`,
                file : e
            })
            navigate("/crop/teacher/d")
        }
        catch(e){
            // console.log("apiPro_img Error= >",e)
        }
    }

    const apiPro_modi = async () =>{
        try {
            // // console.log("@@@@ ",userInfo);
            let data = await apiFn.comApi({
                state : {
                    table : "user_credential",// userInfo["type"] == 1 ? "student" : "teacher",
                    // key : `id:${userInfo["id"]}`,
                    set: [
                        {
                            key: `name:${userInfo["id"]}`,
                            value: {
                                intro : regiData["intro"]
                             }
                        }
                    ]

                }
            })
            // console.log("apiPro Modi => ",data);
            navigate("/regiFive/"+params["who"]+"/c");
        }
        catch(e){
            navigate("/regiFive/"+params["who"]+"/c");
            util.c_err_log(`apiPro_modi Error => ${e}`)
        }
    }
    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])


    useEffect(()=>{
        delete regiData["intro"]
    },[])

    return (
        <Basic>
            <RegiForm
                num={4}
                isTeacher={isTeacher}
                title={
                    <div style={{
                        display:"flex",
                        // color : "lightgoldenrodyellow"
                    }}>
                        {"프로필 입력 "}
                        <div style={{
                            marginLeft : "5px",
                            color : isTeacher ?  style.common.t_color : style.common.s_color
                        }}>
                            (선택)
                        </div>
                    </div>
                }
            />
            <div style={Register_Four_style.profile_c}>
                프로필 사진 등록
                <div style={Register_Four_style.profile_w}>
                    <div style={Register_Four_style.profile_b}>
                        <input
                            id="local_file"
                            type="file"
                            accept='image/*'
                            style={{ display : "none" }}
                            onChange={(e)=>{
                                apiPro_img(e)
                                // util.fileOnchange(e,(event,r)=>{
                                //     setImg(r)
                                // })
                            }}
                        />
                        <label
                            htmlFor='local_file'
                        >

                            <img
                                onError={(e)=>{
                                    // setIsLoad(false)
                                    e.currentTarget.src = isTeacher ? photopick_teacher : photopick_student
                                }}
                                src={
                                    userInfo["id"] ?
                                    (
                                    constants.apiUrl
                                    +
                                    // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                                    ''
                                    +
                                    `${isTeacher ? "/teacher/" : "/student/"}${userInfo["id"]}`
                                    // `${isTeacher ? "/teacher/" : "/student/"}${userInfo["id"].normalize('NFD')}`
                                    // '/teacher/'+params["id"].normalize('NFD')
                                    +
                                    '.jpg?'
                                    +
                                    util.getRandomInt(1000,9999)
                                    )
                                    :
                                    isTeacher ? photopick_teacher : photopick_student
                                }
                                style={Register_Four_style.profile_b_i}
                            />
                        </label>
                    </div>

                </div>
            </div>
            <div style={Register_Four_style.desc_c}>
                <UiInput_title
                    inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                    placeholder="입력해주세요."
                    title="나를 소개하는 글"
                    type="desc"
                    maxLength={30}
                    dsecStyle={{
                        minHeight : "80px",
                    }}
                    inputValue={regiData["intro"] && regiData["intro"]}
                    fnChange={(e)=>{
                        // // console.log("@#@#@#@# ",e.target.value)
                        // setId(e.target.value)
                        setRegiData({
                            ...regiData,
                            intro : e.target.value
                        })
                    }}
                />
            </div>

            <div style={Register_One_style.b_c}>
                <UiBtn
                    title="나중에 할래요"
                    fnClick={()=>{
                        navigate("/regiFive/"+params["who"]+"/c")
                    }}
                />
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor :
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        ,
                        backgroundColor :
                        ( isTeacher ? style.common.t_color : style.common.s_color )

                    }}
                    title="다음"
                    fnClick={()=>{
                        apiPro_modi();
                        // apiPro_regi();
                        // navigate("/regiFive/"+params["who"]);
                    }}
                />
            </div>
        </Basic>
    )
}

export default Register_Four
