import { useLazyQuery, useQuery } from "@apollo/client";
import { PaymentContext } from "asset/context";
import { getUser } from "asset/storage";
import style from "asset/style";
import Basic from "common/frame/Basic";
import SelectTicket from "pages/Payment/Entrance/SelectTicket";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  CheckPaymentLectureByTeacherDocument,
  CheckPaymentLectureDocument,
  GetLectureByStudentDocument,
  GetLectureByTeacherDocument,
} from "types/graphql/graphql";
import SelectTeacherModal from "./SelectTeacherModal";

const SelectLecture = ({ setFileObj, setQnaData }) => {
  const userInfo = getUser();
  const navigate = useNavigate();
  const params = useParams();
  const hostname = window.location.hostname;
  let channel = hostname.split(".")[0];
  if (["com", "dev", "localhost"].includes(channel)) {
    channel = "default";
  }

  const {
    isPaymentModalOpen,
    setPaymentModalOpen,
    isSelectTeacherModalOpen,
    setIsSelectTeacherModalOpen,
    setSelectedLectureId,
    setSelectedTeacherId,
    selectedLectureId,
    selectedTeacherId,
    academyId,
    setPaymentOpen,
    setAcademyId,
  } = useContext(PaymentContext);

  const [selectedAcademyId, setSelectedAcademyId] = useState<string | null>(null);
  // variables: { memberId: userInfo.id, academyId: academyId },

  const [getLectureByStudent, { data: byStudentLecture }] = useLazyQuery(
    GetLectureByStudentDocument
  );

  const [getLectureByTeacher, { data: byTeacherLecture }] = useLazyQuery(
    GetLectureByTeacherDocument
  );

  const [checkPaymentLectureByTeacher, { data: teacherQuota }] = useLazyQuery(
    CheckPaymentLectureByTeacherDocument
  );

  const { data: checkLecture } = useQuery(CheckPaymentLectureDocument, {
    fetchPolicy: "network-only",
    variables: { lectureId: String(selectedLectureId) },
    skip: !selectedLectureId,
  });

  useEffect(() => {
    setSelectedLectureId(null);
  }, []);

  const lectureList = useMemo(() => {
    if (params.teacherId) {
      return byTeacherLecture?.getLectureByTeacher?.lectures?.map((lecture) => {
        return {
          id: lecture.id,
          name: lecture.name,
          academyId: lecture.academyId,
          academyName: lecture.academyName,
        };
      }, []);
    } else {
      return byStudentLecture?.getLectureByStudent?.lectures?.map((lecture) => {
        return {
          id: lecture.id,
          name: lecture.name,
          academyId: lecture.academyId,
          academyName: lecture.academyName,
        };
      }, []);
    }
  }, [byStudentLecture, params, byTeacherLecture]);

  useEffect(() => {
    if (params.teacherId) {
      getLectureByTeacher({
        variables: {
          teacherId: params.teacherId,
          studentId: userInfo.id,
          academyId: params.academyId,
        },
      });
    } else {
      getLectureByStudent({
        variables: { memberId: userInfo.id, academyId },
      });
    }
  }, [params]);

  useEffect(() => {
    if (!selectedTeacherId) {
      setSelectedLectureId(null);
    }
  }, [selectedTeacherId]);

  // 해당 수업의 학원이 결제 기능 사용중인지 체크
  useEffect(() => {
    if (!checkLecture) return;
    if (teacherQuota?.checkPaymentLectureByTeacher?.dashboard.BASIC < 1) {
      return alert("오늘 선생님은 질문을 받을 수 없어요. 내일 다시 질문해주세요.");
    }
    setAcademyId(checkLecture?.checkPaymentLecture?.academyId);
    // 결제 기능 사용 중일 때
    if (checkLecture?.checkPaymentLecture?.isPayment) {
      setPaymentOpen("select");
      setSelectedAcademyId(checkLecture?.checkPaymentLecture?.academyId);
      setPaymentModalOpen(true);
      // 결제 기능을 사용 중이지 않을 때
      // qna탭 질문하기
    } else if (!params.teacherId) {
      setPaymentOpen("none");
      setIsSelectTeacherModalOpen(true);
      // 선생님탭 질문하기
    } else {
      setPaymentOpen("none");
      document.getElementById("question").click();
    }
  }, [checkLecture, teacherQuota]);

  return (
    <>
      <Basic title={"새 질문 만들기"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: style.common.borderColor2,
                height: "20px",
                padding: "10px",
              }}
            >
              질문할 수업을 선택해주세요.
            </div>
          </div>
          {lectureList?.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
                fontSize: 18,
              }}
            >
              질문할 수업이 없습니다. 수업에 참여한 뒤 질문을 해주세요.
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "0px 0px",
            }}
          >
            <input
              id="question"
              type="file"
              accept="image/*"
              style={{
                display: "none",
              }}
              onChange={(e) => {
                setQnaData({ tid: params.teacherId });
                setSelectedTeacherId(params.teacherId);
                // 파일을 고르면 크롭 화면으로 이동
                if (e.target.files.length === 1) {
                  setFileObj({
                    name: ``,
                    file: e,
                  });
                  navigate(`/crop/student/qnaRegi`);
                } else {
                  setFileObj(e.target.files);
                }
              }}
              multiple
            />
            {lectureList?.map((lecture) => {
              return (
                <button
                  key={lecture.id}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    backgroundColor:
                      String(selectedLectureId) === lecture.id
                        ? style.common.s_color_msg
                        : "transparent",
                    border: "1px solid #a0a0a0",
                    borderRadius: 4,
                    marginBottom: 8,
                    height: 48,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    setSelectedLectureId(Number(lecture.id));
                    setSelectedAcademyId(lecture.academyId);
                    if (params.teacherId) {
                      checkPaymentLectureByTeacher({
                        variables: {
                          lectureId: lecture.id,
                          teacherId: params.teacherId,
                        },
                      });
                    }
                  }}
                >
                  <div>
                    {lecture.name}
                    <span style={{ fontSize: 12, color: "#a0a0a0" }}>
                      &nbsp;|&nbsp;
                      {lecture.academyName}
                    </span>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </Basic>
      {isSelectTeacherModalOpen && (
        <SelectTeacherModal
          onSubmit={(e, tid) => {
            if (!tid) {
              alert("선생님을 선택해주세요");
              return;
            }
            setQnaData({ tid });
            setSelectedTeacherId(tid);
            setIsSelectTeacherModalOpen(false);
            // 파일을 고르면 크롭 화면으로 이동
            if (e.target.files.length === 1) {
              setFileObj({
                name: ``,
                file: e,
              });
              navigate(`/crop/student/qnaRegi`);
            } else {
              setFileObj(e.target.files);
            }
          }}
          onCancel={() => {
            setIsSelectTeacherModalOpen(false);
            setFileObj({});
          }}
        />
      )}
      {isPaymentModalOpen && <SelectTicket selectedAcademyId={selectedAcademyId} />}
    </>
  );
};
export default SelectLecture;
