import crypto from "crypto-js";
import moment from "moment-timezone";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
import constants from "./constants";
import store from "./store";

function localTimeSet(date, format) {
  const year = date.substring(0, 4); //년도 뒤에 두자리
  const month = date.substring(4, 6); //월 2자리 (01, 02 ... 12)
  const day = date.substring(6, 8); //일 2자리 (01, 02 ... 31)
  const hour = date.substring(8, 10);
  const minute = date.substring(10, 12);
  const seconds = date.substring(12);
  const dateFormat = `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;

  return moment.utc(dateFormat).tz("Asia/Seoul").format(format);
}

function utilClass() {
  this.getYoutubeId = (content) => {
    if (!content) return;
    var tag = "";
    if (content) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var matchs = content.match(regExp);
      if (matchs) {
        tag = matchs[7];
      }
      return tag;
    }
  };
  this.debounce = (func, timeout = 600) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  this.c_log = (value) => {
    // console.log("%c"+value,"color:green")
  };
  this.c_err_log = (value) => {
    // console.log("%c"+value,"color:lightgoldenrodyellow")
  };
  this.c_gropulog = (groupName, obj, text, isOn) => {
    // // console.log("%c"+obj,"color:yellow")
    // console.table()
    if (isOn) {
      // console.log(`[${groupName}] Req `,obj);
      console.group(`[${groupName}] ${text} => `);
      Object.keys(obj).map((v, i) => {
        if (typeof obj[v] == "object") {
          // console.log("%c"+v+" value","color:red")
          if (Array.isArray(obj[v])) {
            if (obj[v].length > 2) {
              // console.log(obj[v])
            } else {
              obj[v].map((g, h) => {
                console.table(g);
              });
            }
          } else {
            console.table(obj[v]);
          }
          // console.log("%c================================" ,"color:red")
        } else {
          // console.log("%c"+v + " %c: "+"%c"+obj[v],"color:red","color:'white","color:orange")
        }
      });
      console.groupEnd("=================");
    }
  };
  this.objLog = (obj) => {
    Object.keys(obj).map((v, i) => {
      // console.log("%c"+v,"color:yellow",": "+obj[v])
    });
  };
  this.getUUid = () => {
    try {
      return uuidv4();
    } catch (e) {}
  };
  this.setCookie = (key, value, expiredays) => {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + expiredays); // 현재 시각 + 일 단위로 쿠키 만료 날짜 변경
    //todayDate.setTime(todayDate.getTime() + (expiredays * 24 * 60 * 60 * 1000)); // 밀리세컨드 단위로 쿠키 만료 날짜 변경
    document.cookie = key + "=" + value + "; path=/; expires=" + todayDate.toGMTString() + ";";
  };
  this.getCookie = (key) => {
    try {
      key = new RegExp(key + "=([^;]*)"); // 쿠키들을 세미콘론으로 구분하는 정규표현식 정의
      return key.test(document.cookie) ? RegExp.$1 : ""; // 인자로 받은 키에 해당하는 키가 있으면 값을 반환
    } catch (e) {
      //// console.log("GET COOKIE ERRor => ",e)
    }
  };
  this.delCookie = (key) => {
    let todayDate = new Date();
    document.cookie = key + "=; path=/; expires=" + todayDate.toGMTString() + ";"; // 현재 시각 이전이면 쿠키가 만료되어 사라짐.
  };
  this.boolCheckCookie = (key) => {
    try {
      return this.getCookie(key) != "" ? true : false;
    } catch (e) {
      //// console.log("boolcheck Cookie Error ",e)
    }
  };
  this.postWeb = (msg) => {
    try {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(msg);
      } else {
      }
    } catch (e) {}
  };
  this.formatDate = (seconds) => {
    const secondsNumber = parseInt(seconds, 10);
    let hours = Math.floor(secondsNumber / 3600);
    let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
    let totalSeconds = secondsNumber - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (totalSeconds < 10) {
      totalSeconds = `0${totalSeconds}`;
    }
    if (hours > 0) {
      return `${hours}:${minutes}:${totalSeconds}`;
    } else {
      return `${minutes}:${totalSeconds}`;
    }
  };
  this.formatTimeKr = (seconds) => {
    const secondsNumber = parseInt(seconds, 10);
    let hours = Math.floor(secondsNumber / 3600);
    let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
    let totalSeconds = secondsNumber - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (totalSeconds < 10) {
      totalSeconds = `0${totalSeconds}`;
    }
    if (hours > 0) {
      return `${hours}시간${minutes}분${totalSeconds}초`;
    } else {
      return `${minutes}분${totalSeconds}초`;
    }
  };
  this.textTrim = (value, length) => {
    try {
      if (value.trim().length > length) {
        return value.substring(0, length) + "...";
      } else {
        return value;
      }
    } catch (e) {
      // // console.log("textTrim Error => ",e);
    }
  };
  this.spaceTrim = (value) => {
    try {
      // // console.log("@@ ",value)
      // // console.log("@@ ",value.replace(/ /g,""))
      return value.replace(/ /g, "");
    } catch (e) {}
  };
  this.spaceTrim_qr = (value) => {
    try {
      // return value.replace(/ /g,"");
      return `replace(${value},' ','')`;
    } catch (e) {}
  };
  // this.convertToDateTime = (timeStamp) => {
  //     let _timeStamp = timeStamp;
  //     let date;
  //     if (timeStamp<9999999999) {
  //         _timeStamp = (_timeStamp * 1000)
  //         // _timeStamp = String(timeStamp)+"000"
  //     }
  //     date = new Date(Number(_timeStamp));
  //     // let date = new Date(Number(_timeStamp));
  //     var year = date.getFullYear().toString().slice(-2); //년도 뒤에 두자리
  //     var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
  //     var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
  //     var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
  //     var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)
  //     var second = ("0" + date.getSeconds()).slice(-2); //초 2자리 (00, 01 ... 59)

  //     return year + "/" + month + "/" + day +"\n" + hour + ":" + minute + ":" + second;
  // }
  this.convertToDateTime = (date) => {
    try {
      // let date = new Date(Number(_timeStamp));
      var year = date.substring(2, 4); //년도 뒤에 두자리
      var month = date.substring(4, 6); //월 2자리 (01, 02 ... 12)
      var day = date.substring(6, 8); //일 2자리 (01, 02 ... 31)

      var c_date = new Date();
      var c_year = c_date.getFullYear();
      var c_month = ("0" + (c_date.getMonth() + 1)).slice(-2);
      var c_day = ("0" + c_date.getDate()).slice(-2);

      if (date.substring(0, 8) == c_year + c_month + c_day) {
        return date.substring(8, 10) + ":" + date.substring(10, 12);
      } else {
        return year + "/" + month + "/" + day;
      }
    } catch (e) {
      // // console.log(e)
    }
  };
  this.convertToDateTime_form2 = (date) => {
    console.log(date);
    return localTimeSet(date, "YYYY.MM.DD");
    // try {
    //     // let date = new Date(Number(_timeStamp));
    //     var year = date.substring(0,4); //년도 뒤에 두자리
    //     var month = date.substring(4,6) //월 2자리 (01, 02 ... 12)
    //     var day =date.substring(6,8) //일 2자리 (01, 02 ... 31)
    //
    //     return year + "." + month + "." + day
    //
    // }
    // catch(e){
    // }
  };
  this.convertToDateTime_form3 = (date) => {
    return localTimeSet(date, "MM/DD");
  };
  this.convertToDateTime_form4 = (date) => {
    return localTimeSet(date, "HH:mm");
  };
  this.isODD = (num) => {
    if (num % 2 == 0) {
      return false;
    }
    if (num % 2 == 1) {
      return true;
    }
  };
  this.converToSecond = (second) => {
    try {
      // // console.log("second => ",second)
      var sec_num = parseInt(second, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      // console.log("sec_num => ",sec_num)
      // console.log("hours => ",hours)
      // console.log("minutes => ",minutes)
      // console.log("seconds => ",seconds)

      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    } catch (e) {
      // console.log(e)
    }
  };
  this.converToMinute = (second) => {
    try {
      // console.log("converToMinute second -> ",second) // 22009 초
      // console.log("converToMinute minute -> ",second/60) // 366.81 분
      // console.log("converToMinute Hour -> ",second/60/60) // 366.81 분

      var hours = Math.floor(second / 3600);
      var minutes = Math.floor((second - hours * 3600) / 60);
      var seconds = second - hours * 3600 - minutes * 60;

      var aaa = Math.floor(hours * 60);

      // console.log("hours => ",hours)
      // console.log("minutes => ",minutes)
      // console.log("seconds => ",seconds)
      // console.log("aaaa => ",aaa)

      // console.log("Mind => ",(hours * 60)+minutes,seconds)
      // 06 06 48

      return hours * 60 + minutes + ":" + seconds;
    } catch (e) {}
  };
  this.replace_mp4 = (data) => {
    return data
      .replace(".MOV", ".mp4")
      .replace(".mov", ".mp4")
      .replace(".avi", ".mp4")
      .replace(".wmv", ".mp4")
      .replace(".MP4", ".mp4");
  };
  this.converToHour = (second) => {
    try {
      // // console.log("second => ",second)
      if (second > 0) {
        var sec_num = parseInt(second, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = sec_num - hours * 3600 - minutes * 60;

        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "00:00:00";
      }
    } catch (e) {}
  };
  this.convertToDateTime_day = (timeStamp) => {
    let _timeStamp = new Date(timeStamp).getTime();
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return year.substring(2, 4) + "/" + month + "/" + day;
  };
  this.convertToDateTime_day_form2 = (timeStamp) => {
    let _timeStamp = new Date(timeStamp).getTime();
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return year.substring(2, 4) + "." + month + "." + day;
  };
  this.convertToDateTime_full = (date) => {
    return localTimeSet(date, "YYYY/MM/DD HH:mm");
    // try {
    //     // let date = new Date(Number(_timeStamp));
    //     var year = date.substring(0,4); //년도 뒤에 두자리
    //     var month = date.substring(4,6) //월 2자리 (01, 02 ... 12)
    //     var day =date.substring(6,8) //일 2자리 (01, 02 ... 31)
    //     var hour =date.substring(8,10)
    //     var minute =date.substring(10,12)
    //
    //     return year + "/" + month + "/" + day +" "+hour + ":"+minute
    //
    // }
    // catch(e){
    // }
  };
  this.convertToDateTime_full_nonedash_nowTime = () => {
    try {
      let dateFormat = new Date();
      let year = dateFormat.getFullYear();
      let month = dateFormat.getMonth() + 1;
      let day = dateFormat.getDate();
      let hour = dateFormat.getHours();
      let minute = dateFormat.getMinutes();
      let seconds = dateFormat.getSeconds();

      if (month < 10) {
        month = "0" + month;
      }

      return year + month + day + hour + minute + seconds;
    } catch (e) {}
  };
  this.convertToDateTime_ymd_dot = (date) => {
    return localTimeSet(date, "YYYY.MM.DD");
    // try {
    //     // let date = new Date(Number(_timeStamp));
    //     var year = date.substring(0,4); //년도 뒤에 두자리
    //     var month = date.substring(4,6) //월 2자리 (01, 02 ... 12)
    //     var day =date.substring(6,8) //일 2자리 (01, 02 ... 31)
    //     var hour =date.substring(8,10)
    //     var minute =date.substring(10,12)
    //
    //     return year + "." + month + "." + day
    //
    // }
    // catch(e){
    // }
  };
  this.convertToDateTime_yymmdd_dot = (date) => {
    return localTimeSet(date, "YY.MM.DD");
    // try {
    //     // let date = new Date(Number(_timeStamp));
    //     var year = date.substring(2,4); //년도 뒤에 두자리
    //     var month = date.substring(4,6) //월 2자리 (01, 02 ... 12)
    //     var day =date.substring(6,8) //일 2자리 (01, 02 ... 31)
    //     var hour =date.substring(8,10)
    //     var minute =date.substring(10,12)
    //
    //     return year + "." + month + "." + day
    //
    // }
    // catch(e){
    // }
  };
  this.convertToDateTime_ymdhm_dot = (date) => {
    return localTimeSet(date, "YYYY.MM.DD HH:mm");
  };
  // this.dataURLtoFile = (dataurl) => {
  //     var arr = dataurl.split(','),
  //         mime = arr[0].match(/:(.*?);/)[1],
  //         bstr = atob(arr[1]),
  //         n = bstr.length,
  //         u8arr = new Uint8Array(n);

  //     while(n--){
  //         u8arr[n] = bstr.charCodeAt(n);
  //     }

  //     return new File([u8arr], {type:mime});
  // }
  this.convertDate = (date) => {
    // console.log(date)
    // console.log(date)
    // console.log(date)
    let year = 0,
      month = 0,
      day = 0,
      hour = 0,
      minute = 0,
      second = 0;
    if (date.length == 4) {
      year = date;
    } else if (date.length == 6) {
      year = date.substring(0, 4);
      month = date.substring(4, 6);
    } else if (date.length == 8) {
      year = date.substring(0, 4);
      month = date.substring(4, 6);
      day = date.substring(6, 8);
    } else if (date.length == 10) {
      year = date.substring(0, 4);
      month = date.substring(4, 6);
      day = date.substring(6, 8);
      hour = date.substring(8, 10);
    } else if (date.length == 12) {
      year = date.substring(0, 4);
      month = date.substring(4, 6);
      day = date.substring(6, 8);
      hour = date.substring(8, 10);
      minute = date.substring(10, 12);
    } else if (date.length == 14) {
      year = date.substring(0, 4);
      month = date.substring(4, 6);
      day = date.substring(6, 8);
      hour = date.substring(8, 10);
      minute = date.substring(10, 12);
      second = date.substring(12, 14);
    }
    let d = new Date();
    d.setFullYear(year);
    d.setMonth(parseInt(month) - 1);
    d.setDate(day);
    // d.setUTCHours(hour)
    d.setHours(hour);
    d.setMinutes(minute);
    d.setSeconds(second);

    return this.getTimes(d);
  };
  this.getTimes = (date) => parseInt(Math.round(date ? date : new Date()) / 1000);
  this.fileOnchange = (e, cB) => {
    try {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        cB(e, reader.result);
      };
    } catch (e) {
      util.c_err_log("fileOnChange Error => ", e);
    }
  };
  this.crypto = (v) => {
    let crpyto_ = crypto.enc.Base64.stringify(crypto.SHA256(v));
    // console.log("crypto => ",crpyto_)
    return crpyto_;
  };
  this.getS3Folder = () => {
    if (constants.apiUrl.includes("dev.com")) return "test";
    else return "";
  };
  this.dataURLtoFile = (dataurl, fileName) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)?.[1] ?? "application/octet-stream",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };
  this.getRandomInt = (min, max) => {
    try {
      // min = Math.ceil(min);
      // max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
      // return ""
    } catch (e) {
      console.log("getRandomInt Error => ", e);
    }
  };
  this.toBase64 = (file, isBase64) => {
    return new Promise((resolve, reject) => {
      // console.log(`originalFile size ${file.size} `);

      let options = {
        maxSizeMb: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      let fileStat = "blob";
      if (isBase64) fileStat = "base64";

      Resizer.imageFileResizer(
        file,
        1920,
        1920,
        "JPEG",
        90,
        0,
        (uri) => {
          // // console.log("URI => uri " ,uri)
          resolve(uri);
        },
        fileStat
      );
    });
  };
  this.getCrop = (image, crop, fileName) =>
    new Promise((r, j) => {
      try {
        // console.log(image)
        const canvas = document.createElement("canvas");
        const pixelRatio = window.devicePixelRatio;

        // const scaleX = image.naturalWidth / image.width;
        // const scaleY = image.naturalHeight / image.height;

        const scaleX = image.width;
        const scaleY = image.height;

        const rate = crop.width / crop.height;

        canvas.height = image.height;
        canvas.width = image.height * rate;

        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          canvas.width,
          canvas.height
        );

        canvas.toBlob(
          (b) => {
            Resizer.imageFileResizer(
              b,
              1920,
              1920,
              "JPEG",
              100,
              0,
              (uri) => {
                // console.log("@@@ ",uri)
                r(uri);
              },
              "base64"
            );
          },
          "image/jpeg",
          1.0
        );
      } catch (e) {
        // console.log("get Crop Error => ",e)
      }
    });
  this.putVideo = async (s3File, _data, bId, c_name) => {
    try {
      if (s3File && s3File.name) {
        var arrS3File = s3File.name.split(".");
        var bbId;
        if (Object.keys(_data).length > 0) {
          bbId = _data.id;
          // console.log(_data.id);
        } else {
          bbId = bId;
          // console.log(bId);
        }

        // console.log("arrS3File => ",arrS3File)
        var name = bbId + "." + arrS3File[arrS3File.length - 1].toLowerCase();

        var fname = "board/" + name;
        // var fname = name;
        var isQna = false;
        if (c_name) {
          isQna = true;
          fname = c_name;
          name = c_name;
        }

        // console.log("putvideo name => ",name);
        // console.log("putvideo fname => ",fname);
        // // console.log("putVideo => ",fname);

        let uuid = util.getUUid();
        // fname =

        // await apiFn.comApi({
        //     state : {
        //         table : "warn",
        //         ins : [{
        //             content : "type V :" + `s3File : ${s3File} / name : ${name} / uuid : ${uuid} / isQna : ${isQna}`,
        //             reg_date : 0,

        //         }]
        //     }
        // })

        if (["mov", "avi", "wmv"].includes(arrS3File[arrS3File.length - 1].toLowerCase())) {
          // const result = await store.putBucket(s3File, 'up/'+name);
          const result = await store.putBucket(s3File, name, uuid, false, isQna);
          // // console.log("result => ",result);

          // fname = "/board/"+uuid+"."+arrS3File[arrS3File.length-1].toLowerCase()

          // const result1 = await store.mov2mp4(fname, uuid);
          const result1 = await store.mov2mp4(fname);

          // // console.log("result1 => ",result1);
        } else if (["mp4"].includes(arrS3File[arrS3File.length - 1].toLowerCase())) {
          const result = await store.putBucket(s3File, fname, uuid, false, isQna);
          // // console.log("result => ",result);

          // fname = "/board/"+uuid+"."+arrS3File[arrS3File.length-1].toLowerCase()

          // const result1 = await store.mov2mp4(fname, uuid);
          const result1 = await store.mov2mp4(fname, uuid);

          // // console.log("result1 =" ,result1);
        } else {
        }
      }
    } catch (e) {
      // console.log("putVideo Error => ",e);
    }
  };
  this.chk_platform = () => {
    try {
      var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

      // alert(varUA)
      if (varUA.indexOf("android") > -1) {
        //안드로이드
        return true;
      } else if (
        varUA.indexOf("macintosh") > -1 ||
        varUA.indexOf("iphone") > -1 ||
        varUA.indexOf("ipad") > -1 ||
        varUA.indexOf("ipod") > -1
      ) {
        //IOS
        return false;
      } else {
        //아이폰, 안드로이드 외
        return "other";
      }
    } catch (e) {
      // console.log(e)
    }
  };
  this.normalize = (value) => {
    try {
      return value.normalize("NFD");
    } catch (e) {
      // console.log("norm Error => ",e);
      return value;
    }
  };
  this.parseSplit = (value) => {
    try {
      let parseSplit = value.substr(-4);
      let data = "";
      // value.split(parseSplit)[0]
      // parseSplit.map((v,i)=>{
      //     if(i == (parseSplit.length -1))return;
      //     else {
      //         data = data + v
      //     }
      // })
      // // console.log(parseSplit)
      // // console.log(parseSplit)
      // // console.log(parseSplit)
      // // console.log(parseSplit)
      // // console.log(parseSplit)
      // // console.log(parseSplit)
      return value.split(parseSplit)[0];
    } catch (e) {}
  };
  this.isKeyExists = (obj, key) => {
    return key in obj;
  };
  this.getCheck = async (path) => {
    try {
      const result = await store.axiosPost({
        cat: "com",
        cmd: "getCheck2",
        key: path,
      });
      // console.log('getCheck2:', result);
      return result["status"];
    } catch (e) {}
  };
  this.copyClip = (clipText) =>
    new Promise((r, j) => {
      try {
        const textarea = document.createElement("textarea");
        textarea.value = clipText;
        textarea.style.top = 0;
        textarea.style.left = 0;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        r(true);
      } catch (e) {
        console.log("COPYCLIP : ", e);
      } finally {
        console.log("END : ");
      }
    });
  this.line = (value) => {
    try {
      let array = [];
      value.split("\n").map((v, i) => {
        array.push(<div>{v}</div>);
      });

      return array;
    } catch (e) {}
  };
  this.removeLocalStorage = () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("pw");
    window.localStorage.removeItem("provider");
  };
  this.nativeLogger = (value) => {
    this.postWeb(`logger::${value}`);
  };
  this.formatCurrency = (number) => `${("" + number).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`;
  this.dataURLtoFile = (dataurl, fileName) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };
}

export function formatToThreeDecimals(score) {
  return Math.round(score * 1000) / 1000;
}

var util = new utilClass();
export default util;
