import {React, useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router'
import style from '../../../asset/style';
import Basic from '../../../common/frame/Basic'
import Register_Intro_Style from './Register_Intro_Style';
import aquery from '../../../asset/Img/aquery.png'
import answer from '../../../asset/Img/answer.png'
import Register_One_style from './Register_One_style';
import {UiCheckBox} from '../../../uiComponents/UiCheckBox/UiCheckBox';
import {KeyboardArrowDown} from '@material-ui/icons';
import UiBtn from '../../../uiComponents/UiBtn/UiBtn';
import RegiForm from './RegiForm';
import store from '../../../asset/store';
import util from '../../../asset/util';
import apiFn from '../../../asset/apiClass';
import axios from "axios";
import constants from "../../../asset/constants";

const Register_One = ({setRegiData}) => {
    const params = useParams();
    const navigate = useNavigate();

    const [agrData, setAgrData] = useState([]);

    const [isTeacher, setIsTeacher] = useState(false);

    const [isChkList, setIsChkList] = useState([]);
    const isAgreeAll = useMemo(() => isChkList.every(item => item), [isChkList]);

    const apiPro_read = async () => {
        try {
            let data = await axios.get(constants.apiUrl+'/tos')
            setIsChkList(new Array(data.data.list.length).fill(false))
            setAgrData([
                ...data.data.list
            ])
        } catch (e) {
            // console.log("Agree Error => ",e)
        }
    }

    useEffect(() => {
        util.c_log("Register_One.jsx")
        apiPro_read()
    }, [])

    useEffect(() => {
        if (params["who"] === "teacher") {
            setIsTeacher(true)
        } else {
            setIsTeacher(false)
        }
    }, [params])

    // 모두 동의 버튼 on/off

    const isPass = useMemo(() => !((agrData || []).some((tos, index) =>
        tos.is_required && !isChkList[index]
    )), [isChkList, agrData])

    useEffect(() => {
        console.log(isPass, isChkList)
    }, [isPass, isChkList])

    return (
        <Basic>
            <RegiForm
                num={1}
                isTeacher={isTeacher}
                title="약관 동의"
            />
            <div style={{
                // marginTop : "30px",
            }}>
                <div style={{
                    ...Register_One_style.section_a_c,
                    justifyContent: "flex-start",
                    padding: "30px 0px 20px 0px",
                    borderBottom: "1px solid black",
                }}
                >
                    <UiCheckBox
                        className={isTeacher ? "t" : "s"}
                        value={"D"}
                        name="D"
                        checked={isAgreeAll}
                        fnClick={() => {
                            if (isAgreeAll)
                                setIsChkList(new Array(agrData.length).fill(false))
                            else
                                setIsChkList(new Array(agrData.length).fill(true))
                        }}
                    />
                    <div style={{
                        ...Register_One_style.section_a_t,
                        fontSize: "14px",
                        fontWeight: "bold",
                    }}>
                        회원가입 약관에 모두 동의합니다.
                    </div>
                </div>
                {
                    agrData && agrData.map((tos, i) => (
                        <ToS key={i} data={tos} isTeacher={isTeacher} isChkList={isChkList} setIsChkList={setIsChkList}
                             index={i}/>
                    ))
                }
            </div>
            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor: isPass ?
                            (isTeacher ? style.common.t_color : style.common.s_color)
                            : style.common.borderColor,
                        backgroundColor: isPass ?
                            (isTeacher ? style.common.t_color : style.common.s_color)
                            : "rgb(200,200,200)"
                    }}
                    title="다음"
                    fnClick={() => {
                        if (isPass) {
                            setRegiData(prev => ({
                                ...prev,
                                agreement: agrData.map((agr, i) => ({agree: isChkList[i], tos_id: agr.id, isRequired: agr.is_required}))
                            }))
                            navigate(params["type"] === "apple" ? ("/regiChk/" + params["who"]) : "/regiThree/" + params["who"])
                        }
                    }}
                />
            </div>
        </Basic>
    )
}
const ToS = ({data, isTeacher, isChkList, setIsChkList, index}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            <div style={Register_One_style.section_a_c}>
                <div style={Register_One_style.section_a_w}>
                    <UiCheckBox
                        checked={isChkList[index]}
                        className={isTeacher ? "t" : "s"}
                        value={"Agr" + data['title']}
                        name={"Agr" + data['title']}
                        fnClick={() => {
                            setIsChkList(prev => {
                                const newCheckList = [...prev]
                                newCheckList[index] = !newCheckList[index]
                                return newCheckList
                            })
                        }}
                    />
                    <div style={Register_One_style.section_a_t}>
                        {data['title']}{data.is_required ? ' (필수)' : ' (선택)'}
                    </div>
                </div>
                <KeyboardArrowDown
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                />
            </div>
            {
                isOpen &&
                <div dangerouslySetInnerHTML={{
                    __html:
                        data['contents'].replace(new RegExp('\n', 'g'), '<br/>')
                }}>
                </div>
            }
        </div>
    )
}

export default Register_One
