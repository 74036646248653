// const apiUrl = 'http://dev.com:8040'
// const apiUrl = 'http://192.168.1.121:8040/'
// const apiUrl = 'http://192.168.1.121:8051/'
// const apiUrl = "http://dev.com:8051/"
// const apiUrl = "http://211.233.37.55:8051/"

// const apiUrl = `http://dev.com:8060` // dev
let isInspect = false; // inspect on/off

// let apiUrl = "";
// let wsurl = "";
// let redirect_url = "";
// let s3_url = "";
// let video_bucket = "";
// if (process.env.NODE_ENV === "production" && process.env.REACT_APP_BUILD_MODE === "live") {
//   apiUrl = `https://api.hiq24.co.kr`; // internal
//   wsurl = `wss://api.hiq24.co.kr`; // internal
//   redirect_url = "https://com.hiq24.co.kr/"; // internal
//   s3_url = "https://api.hiq24.co.kr";
//   video_bucket = "https://storage.googleapis.com/hiqsum-video";
// } else if (process.env.NODE_ENV === "production") {
//   apiUrl = `https://stage-dot-hiqsum-379810.du.r.appspot.com`; // internal
//   wsurl = `wss://stage-dot-hiqsum-379810.du.r.appspot.com`; // internal
//   redirect_url = "https://dev.hiq24.co.kr/"; // internal
//   s3_url = "https://stage-dot-hiqsum-379810.du.r.appspot.com";
//   isDev = true;
//   video_bucket = "https://storage.googleapis.com/hiqsum-test-video";
// } else {
//   apiUrl = process.env.REACT_APP_API_URL ?? `http://localhost:3000`; // server
//   wsurl = process.env.REACT_APP_WS_URL ?? `ws://localhost:3000`; // websocket
//   redirect_url = process.env.REACT_APP_HOST_URL ?? "http://localhost:3001/"; // front
//   s3_url = process.env.REACT_APP_S3_URL ?? "http://localhost:3000"; // upload gcp
//   isDev = true;
//   video_bucket =
//     process.env.REACT_APP_VIDEO_BUCKET ?? "https://storage.googleapis.com/hiqsum-test-video";
// }

const constants = {
  isAcademy: process.env.REACT_APP_CHANNEL_ID && process.env.REACT_APP_CLASSROOM_ID,
  academyId: process.env.REACT_APP_ACADEMY_ID, // HIQDB_class's academy_code = HIQDB_academy's id
  channelId: process.env.REACT_APP_CHANNEL_ID,
  ownerId: process.env.REACT_APP_OWNER_ID,
  classroomId: process.env.REACT_APP_CLASSROOM_ID
    ? parseInt(process.env.REACT_APP_CLASSROOM_ID)
    : null,
  isInspect: isInspect,
  isDev: process.env.NODE_ENV !== "production" || process.env.REACT_APP_BUILD_MODE !== "live",
  s3_url: process.env.REACT_APP_S3_URL,
  // wsurl : `ws://com.hiq24.co.kr:8061`, // internal
  video_bucket: process.env.REACT_APP_VIDEO_BUCKET,
  apiUrl: process.env.REACT_APP_API_URL, // api 호출 Url
  wsurl: process.env.REACT_APP_WS_URL, // 소켓
  redirect_url: `${process.env.REACT_APP_HOST_URL}/`, //
  kakaoClientId: process.env.REACT_APP_KAKAO_CLIENT_ID,
  naverClientId: process.env.REACT_APP_NAVER_LOGIN_KEY,
  naverClientSecret: process.env.REACT_APP_NAVER_SECRET_KEY,
  hostUrl: process.env.REACT_APP_HOST_URL,
  exeternalUrl: "http://jindan.co.kr/",
  editor_uploadUrl: "http://dev.com:8051/upload", // editor 파일 업로드 Url
  editor_apikey: "0qdj1ckqs3gc0opqqmnqzp1a5ajikzgpfdh5vgcfw308fcwm", // editor api Key,
  impToken: "imp85048343",
  mangePage_amount: 10,
  fileSize: 10 * 1024 * 1024 * 1024,
  session: "9ea14cde-9905-11ea-bb37-0242ac130003",
  confirm: {
    cf_1: "회원을 탈퇴하시겠어요?\n(탈퇴시 등록하신 교재,대화 등 모든 정보가 삭제되며 복구할 수 없습니다.",
    cf_2: "선택한 교재를 삭제하시겠어요? \n(교재에 속한 질문이 모두 사라지며 복구할 수 없어요.)",
    cf_3: "선택한 폴더를 삭제하시겠어요? \n(삭제시 폴더 내의 교재는 모두 폴더 바깥으로 이동됩니다.)",
    cf_4: "에 선생님으로 등록을 신청하시겠어요? \n(클래스룸측의 승인 후 등록이 완료됩니다.)",
    cf_5: "수업에 등록을 신청하시겠어요? \n(클래스룸이나 선생님의 승인 후 등록이 완료됩니다.)",
    cf_6: "를 폐강하시겠어요?\n(폐강한 강의는 학생에게 노출되지 않아요.)",
    cf_7: "로그아웃 하시겠어요?",
    cf_8: "선택한 교재의 공유를 취소하시겠어요?",
    cf_9: "를 삭제하시겠어요?\n(삭제한 강의는 다시 복구할 수 없어요.)",
    cf_10: "선택한 교재의 공유를 취소하시겠어요?",
    cf_11: "정보를 변경하시겠어요?",
    cf_12: "변경할 비밀번호가 다릅니다. 다시확인해주세요.",
    cf_13: "비밀번호를 다시 확인해주세요.",
    cf_14: "비밀번호가 변경 되었습니다.",
    cf_15: "선택하신 클래스룸에 등록을 신청하시겠어요?",
    cf_16: "클래스룸 등록 신청을 취소하시겠어요?",
    cf_17: "선택된 선생님을 탈퇴 처리 하시겠어요?",
    cf_18: "선택된 선생님들의 신청을 거절하시겠어요?",
    cf_19: "선택된 선생님들의 신청을 승인하시겠어요?",
    cf_20: "탈퇴 처리 하시겠어요?",
    cf_21: "승인 처리 하시겠어요?",
    cf_22: "교재를 생성하시겠어요?",
    cf_23: "생성이 완료되었습니다.",
    cf_24: "재로그인이 필요합니다.",
    cf_25: "네트워크 오류가 발생했습니다.",
  },
  regex_imo:
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
  checkExp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,100}$/,
};

export const AUTH_CODE = {
  NO_TOKEN: "NO_TOKEN",
  INVALID_TOKEN: "INVALID_TOKEN",
  EXPIRED_TOKEN: "EXPIRED_TOKEN",
  NOT_VERIFIED: "NOT_VERIFIED",
  USER_VERIFIED: "USER_VERIFIED",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
};
export const NEED_LOGIN_CODE = [
  AUTH_CODE.NO_TOKEN,
  AUTH_CODE.INVALID_TOKEN,
  AUTH_CODE.EXPIRED_TOKEN,
  AUTH_CODE.NOT_VERIFIED,
  AUTH_CODE.USER_NOT_FOUND,
  AUTH_CODE.NOT_AUTHORIZED,
];

export const ACADEMY_NAMES = {
  396: "올인원 수학 학원",
  447: "한수위 수학 전문 학원",
  634: "매일의 수학",
  638: "SNEdu",
  761: "서로 국어 학원",
  800: "명작 수학 학원",
  802: "명성 수학 학원",
  816: "코스터디",
  892: "다원MDS",
  928: "종로 영수 전문 학원",
};

export default constants;
