import { CircularProgress } from "@mui/material";
import allinone_logo from "asset/Img/allinone.svg";
import costudy_logo from "asset/Img/costudy.svg";
import dawon_logo from "asset/Img/dawon-intro.png";
import hansuwi_logo from "asset/Img/hansuwi-intro.png";
import hi from "asset/Img/hi.png";
import intro_logo from "asset/Img/intro_logo.png";
import jongro_logo from "asset/Img/jongro-intro.jpg";
import logo_apple from "asset/Img/logo_apple.png";
import logo_kakao from "asset/Img/logo_kakao.png";
import logo_naver from "asset/Img/logo_naver.png";
import math_in_everyday_logo from "asset/Img/math-in-everyday.jpg";
import myeongseong_logo from "asset/Img/myeongseong.png";
import seoro_logo from "asset/Img/seoro.png";
import sne_logo from "asset/Img/sne_logo.png";
import wmm_logo from "asset/Img/wmm-logo.png";
import apiFn from "asset/apiClass";
import constants from "asset/constants";
import store from "asset/store";
import style from "asset/style";
import util from "asset/util";
import axios from "axios";
import crypto from "crypto-js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UiBtn from "../../../uiComponents/UiBtn/UiBtn";
import TestButtonList from "./TestButtonList";


const Intro = ({ setUserInfo, setIsIntro, setRegiData }) => {
  const { Kakao, naver } = window;

  const [kakaoData, setKakaoData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [userInfo_k, setUserInfo_k] = useState({});
  const [userInfo_n, setUserInfo_n] = useState({});

  // var [is]

  let navigate = useNavigate();
  let params = useParams();

  const apiPro_test_park = async () => {
    try {
      store
        .axiosPost({
          cat: "hiq",
          cmd: "newSignIn",
          id: "0YtcNZweMgR0PkembMND",
          pw: "TqXFCKZWbnYkBUP4/rBv1Fd3e+OVScQBZDav2mXSMw4=",
          // id : "nbPavkpUblBOpP4y7yPZ",
          // pw : "gNBcfsiKy7ozdo2WIP9h42Js6FCTB2U4hW2tCvK8bxk="
        })
        .then((res) => {
          // util.c_gropulog("fnNewSignIn",res,"res");
          if (res) {
            store.set("user", {
              ...res,
            });
            if (res["provider"] == "kakao") {
              window.localStorage.setItem("provider", "kakao");
            } else {
              window.localStorage.setItem("provider", "naver");
            }
            window.localStorage.setItem("user", JSON.stringify(res));
            window.localStorage.setItem("pw", "TqXFCKZWbnYkBUP4/rBv1Fd3e+OVScQBZDav2mXSMw4=");
            // window.localStorage.setItem('autoLogin', store.get('autoLogin').toString());
            // r(res)
            // console.log("@@@@@#@# ",res);
            if (res) {
              setUserInfo({
                ...res,
              });
              if (res["type"] == 1) {
                navigate("/message/" + "student" + "/" + "home");
              } else {
                navigate("/message/" + "teacher" + "/" + "home");
              }
            }
          }
        })
        .catch((e) => {
          // //alert(JSON.stringify(e))
          // j(false)
          // console.log('[fnNewSignIn] API Error => ',e)
          // //alert(e);
        });
    } catch (e) {
      // console.log("new Sign In Error => ",e);
    }
  };

  const apiPro_test_t_2 = async () => {
    try {
      store
        .axiosPost({
          cat: "hiq",
          cmd: "newSignIn",
          id: "t1",
          pw: "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=",
          // id : "nbPavkpUblBOpP4y7yPZ",
          // pw : "gNBcfsiKy7ozdo2WIP9h42Js6FCTB2U4hW2tCvK8bxk="
        })
        .then((res) => {
          // util.c_gropulog("fnNewSignIn",res,"res");
          if (res) {
            store.set("user", {
              ...res,
            });
            if (res["provider"] == "kakao") {
              window.localStorage.setItem("provider", "kakao");
            } else {
              window.localStorage.setItem("provider", "naver");
            }
            window.localStorage.setItem("user", JSON.stringify(res));
            window.localStorage.setItem("pw", "TqXFCKZWbnYkBUP4/rBv1Fd3e+OVScQBZDav2mXSMw4=");
            // window.localStorage.setItem('autoLogin', store.get('autoLogin').toString());
            // r(res)
            // console.log("@@@@@#@# ",res);
            if (res) {
              setUserInfo({
                ...res,
              });
              if (res["type"] == 1) {
                navigate("/message/" + "student" + "/" + "home");
              } else {
                navigate("/message/" + "teacher" + "/" + "home");
              }
            }
          }
        })
        .catch((e) => {
          // //alert(JSON.stringify(e))
          // j(false)
          // console.log('[fnNewSignIn] API Error => ',e)
          // //alert(e);
        });
    } catch (e) {
      // console.log("new Sign In Error => ",e);
    }
  };

  const apiPro_test_s_1 = async () => {
    try {
      store
        .axiosPost({
          cat: "hiq",
          cmd: "newSignIn",
          id: "37Njp90kkZJAprHzMA6P",
          pw: "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=",
          // id : "nbPavkpUblBOpP4y7yPZ",
          // pw : "gNBcfsiKy7ozdo2WIP9h42Js6FCTB2U4hW2tCvK8bxk="
        })
        .then((res) => {
          // util.c_gropulog("fnNewSignIn",res,"res");
          if (res) {
            store.set("user", {
              ...res,
            });
            if (res["provider"] == "kakao") {
              window.localStorage.setItem("provider", "kakao");
            } else {
              window.localStorage.setItem("provider", "naver");
            }
            window.localStorage.setItem("user", JSON.stringify(res));
            window.localStorage.setItem("pw", "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=");
            // window.localStorage.setItem('autoLogin', store.get('autoLogin').toString());
            // r(res)
            // console.log("@@@@@#@# ",res);
            if (res) {
              setUserInfo({
                ...res,
              });
              if (res["type"] == 1) {
                navigate("/message/" + "student" + "/" + "home");
              } else {
                navigate("/message/" + "teacher" + "/" + "home");
              }
            }
          }
        })
        .catch((e) => {
          // //alert(JSON.stringify(e))
          // j(false)
          // console.log('[fnNewSignIn] API Error => ',e)
          // //alert(e);
        });
    } catch (e) {
      // console.log("new Sign In Error => ",e);
    }
  };

  const apiPro_provider_signIn = async (provider, info) => {
    try {
      //alert("a")
      let state = {};
      if (provider == "k") {
        state["kakao"] = {
          ...info,
        };
      } else if (provider == "n") {
        state["naver"] = {
          ...info,
        };
      }
      //alert("b")
      let isOk = await apiFn.providerSignIn({
        state: state,
      });

      //alert("c")
      let p_userInfo = {};
      if ([-1, 0].includes(isOk.status)) {
        if (provider == "k") {
          p_userInfo["provider"] = "kakao";
          p_userInfo["oid"] = info["id"];
          p_userInfo["name"] = info["kakao_account"]["profile"]["nickname"];
          if (info["kakao_account"]["phone_number"]) {
            p_userInfo["tel"] = info["kakao_account"]["phone_number"].split("+82 ")[1];
          }
          p_userInfo["email"] = info["kakao_account"]["email"];
        } else if (provider == "n") {
          p_userInfo["provider"] = "naver";
          p_userInfo["oid"] = info["id"];
          p_userInfo["id"] = info["id"];
          p_userInfo["name"] = info["name"];
          p_userInfo["tel"] = info["mobile"];
          p_userInfo["email"] = info["email"];
        }
        setRegiData({
          ...p_userInfo,
        });
        if (isOk.status === -1) {
          navigate("/regiIntro/de");
        } else {
          const pw = crypto.enc.Base64.stringify(crypto.SHA256(p_userInfo["oid"]));
          const { data } = await axios.post(
            `${constants.apiUrl}/auth/${isOk.userData.id}/userCredential`,
            {
              ...p_userInfo,
              channelId: constants?.channelId,
              pw,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (data) {
            let isOk = await apiFn.fnNewSignIn({
              state: {
                id: data.id,
                pw: info["id"],
              },
            });
            if (!isOk) {
              alert("로그인에 실패했습니다. 문제가 계속 발생하면 문의해주세요.");
              return;
            }
            setUserInfo({
              ...isOk,
            });
            alert("로그인 정보를 기록하는데 성공했습니다.");
            if (!constants.isAcademy) {
              navigate(`/message/${isOk.type === 1 ? "student" : "teacher"}/home`);
            }
          }
        }
      } else {
        let isOk_login = await apiFn.fnNewSignIn({
          state: {
            id: isOk["id"],
            pw: info["id"],
            // pw : regiData["pw"]
          },
        });

        //alert(JSON.stringify(isOk_login))
        setUserInfo({
          ...isOk_login,
        });
        if (constants.isAcademy) {
          let { list: lectureList } = await apiFn.comApi({
            state: {
              table: "member_class",
              page: 1,
              amount: 9999,
              sort: `reg_date desc`,
              ref: [
                "HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid",
                "HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid",
                "HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid",
                "HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid",
                "HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid",
                "HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid",
                "HIQDB_class.img|HIQDB_class.id=HIQDB_member_class.cid",
                "HIQDB_class.reg_id|HIQDB_class.id=HIQDB_member_class.cid",
              ],
              where: {
                "HIQDB_member_class.cid": `='${constants.classroomId}'`,
                "HIQDB_member_class.mid": `='${isOk_login.id}'`,
              },
            },
          });
          if (lectureList.length <= 0) {
            try {
              let sendMsg = {
                type: "suup_apply",
                orderMid: isOk_login.id,
                targetMid: isOk_login.id,
                cid: constants.classroomId,
                channel: constants.channelId,
              };
              if (isOk_login.status === 1) {
                sendMsg["type"] = "student_apply";
              }
              let isOk = await apiFn.comApi({
                state: {
                  table: "member_class",
                  sendMsg: sendMsg,
                  ins: [
                    {
                      mid: isOk_login.id,
                      cid: constants.classroomId,
                      type: isOk_login.type === 1 ? 0 : 6,
                      status: 0,
                      reg_date: 0,
                    },
                  ],
                },
              });
              const res = await (
                await fetch(
                  `${constants.apiUrl}/user/${isOk_login.id}/classroom/${constants.classroomId}/class`
                )
              ).json();
              if (res.length <= 0) {
                navigate(`/regiFive/${isOk_login.type === 2 ? "teacher" : "student"}/c`);
              } else {
                alert("가입 승인 대기중입니다\n승인을 기다려주세요");
              }
              return;
            } catch (e) {
              alert("학원에 가입을 요청하는 도중 오류가 발생했습니다\n다시 시도해주세요");
              return;
            } finally {
              setIsLoading(false);
              util.removeLocalStorage();
            }
          } else if (lectureList[0].status === 0) {
            try {
              const res = await (
                await fetch(
                  `${constants.apiUrl}/user/${isOk_login.id}/classroom/${constants.classroomId}/class`
                )
              ).json();
              const isAlreadyInClass =
                res.filter((classData) => classData.status === 1).length >= 1;
              if (res.length <= 0) {
                navigate(`/regiFive/${isOk_login.type === 2 ? "teacher" : "student"}/c`);
              } else if (isAlreadyInClass) {
                let isOk = await apiFn.comApi({
                  state: {
                    table: "member_class",
                    set: [
                      {
                        key: `cid:${constants.classroomId}' and mid='${isOk_login.id}`,
                        value: {
                          status: 1,
                        },
                      },
                    ],
                  },
                });
                navigate(`/message/${isOk_login.type === 2 ? "teacher" : "student"}/home`);
              } else {
                alert("가입 승인 대기중입니다\n승인을 기다려주세요");
                util.removeLocalStorage();
              }
            } catch (e) {
              console.error(e);
            } finally {
              setIsLoading(false);
              return;
            }
          }
        }
        if (isOk_login["type"] == 1) {
          navigate(`/message/student/home`);
        } else {
          navigate(`/message/teacher/home`);
        }
      }

      // //alert("d")
      // console.log("isOk => ",isOk)
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // //alert("서버 응답이 원하하지 않습니다.");
      //alert("e 2")
      //alert(JSON.stringify(e))
      // console.log("apiPro_provider_signIn => ",e)
    }
  };

  const naverRef = useRef();

  const initializeNaverLogin = () => {
    try {
      const naverLogin = new naver.LoginWithNaverId({
        clientId: constants.naverClientId,
        callbackUrl: constants.redirect_url + "naver",
        isPopup: false /* 팝업을 통한 연동처리 여부, true 면 팝업 */,
        loginButton: { color: "green", type: 3, height: 52, width: 200 },
      });

      naverLogin.init();

      naverLogin.getLoginStatus((status) => {
        try {
          // // console.log('로그인 성공 시 실행되는 함수 status >>>>',status);
          // // console.log('naverLogin >>>>',naverLogin);
          // // console.log('naverLogin >>>>',);
          if (status) {
            window.sessionStorage.setItem("n_token", naverLogin["accessToken"]["accessToken"]);
            apiPro_provider_signIn("n", naverLogin["user"]);
            setUserInfo_n({
              ...naverLogin.user,
            });
          }
        } catch (e) {
          // console.log(e)
        }
      });
    } catch (e) {
      // console.log("naver init Error => ",e);
    }
  };

  const getKakaoUserInfo = async (result) => {
    try {
      let data = "";
      let response = await Kakao.API.request({ url: "/v2/user/me" });
      data = response;
      apiPro_provider_signIn("k", response);
      setUserInfo_k({
        ...data,
      });
    } catch (e) {
      setIsLoading(false);
    }
  };

  const kakao_login = async () => {
    try {
      //alert("1");
      setIsLoading(true);
      const kakaoHeader = {
        // 'Authorization': "e2b00386ffb3d944c376f412530f40a1",
        "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
      };
      const data = {
        grant_type: "authorization_code",
        client_id: constants.kakaoClientId,
        redirect_uri: constants.redirect_url,
        code: window.location.search.split("?code=")[1],
      };

      const queryString = Object.keys(data)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(data[k]))
        .join("&");

      const result = await axios.post(
        "https://kauth.kakao.com/oauth/token",
        queryString,
        // data,
        { headers: kakaoHeader }
      );

      Kakao.Auth.setAccessToken(result["data"]["access_token"]);
      // if(!kakaoData["id"]){

      // }

      setKakaoData({
        ...result,
      });

      // //alert(JSON.stringify(result));
      // await getKakaoUserInfo();
      await getUserInfo_kakao(result);
    } catch (e) {
      setIsLoading(false);
      //alert("카카오 서버 응답이 원할하지 않습니다.")
      //alert(JSON.stringify(e))
      // console.log(e)
    }
  };

  const getUserInfo_kakao = async (result) => {
    try {
      // console.log("@#@#@# => ",result)
      //alert("4")
      Kakao.Auth.setAccessToken(result["data"]["access_token"]);
      // Kakao.Auth.setAccessToken(kakaoData["data"]["access_token"])
      //alert("5")
      await getKakaoUserInfo();
    } catch (e) {
      //alert("6")
      setIsLoading(false);
      //alert(JSON.stringify(e))
      // console.log("getUserInfo_kakao Error => ",e);
    }
  };

  useEffect(() => {
    // Kakao.API.request({
    //     url: '/v1/user/unlink',
    //     success: function(response) {
    //       // console.log(response);
    //     },
    //     fail: function(error) {
    //       // console.log(error);
    //     },
    //   });
    // //alert(window.location.search)

    initializeNaverLogin();
  }, []);
  useEffect(() => {
    // alert(JSON.stringify(window.location.href))
    if (window.location.search.includes("?code=")) {
      // setIsLoading(true)
      // if(userInfo_k["id"])return;
      kakao_login();
    }
  }, [navigate]);
  // },[window.location.search])

  useEffect(() => {
    //    getUserInfo_kakao();
  }, [kakaoData]);

  useEffect(() => {
    // console.log("userInfo_k => ",userInfo_k)
  }, [userInfo_k]);

  const logo = useMemo(() => {
    switch (constants.channelId) {
      case "jym":
        return jongro_logo;
      case "msm":
        return myeongseong_logo;
      case "aio":
        return allinone_logo;
      case "csd":
        return costudy_logo;
      case "wmm":
        return wmm_logo;
      case "srk":
        return seoro_logo;
      case "sne":
        return sne_logo;
      case "mie":
        return math_in_everyday_logo;
      case "mds":
        return dawon_logo;
      case "hsw":
        return hansuwi_logo;
      default:
        return intro_logo;
    }
  }, [constants.channelId]);
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent:"space-between",
        // justifyContent:"flex-end",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "300px",
          justifyContent: "flex-end",
          flexGrow: 2,
          marginBottom: "0px",
        }}
      >
        <div
          style={{
            width: "200px",
            padding: "20px 0px 0px 0px",
          }}
        >
          <img
            src={logo}
            style={{
              width: "100%",
            }}
          />
        </div>
        {!constants.isAcademy ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "18px",
                // fontWeight:600,
                fontWeight: "normal",
                // fontWeight:200,
                // flexGrow:1,
                justifyContent: "flex-end",
                color: "#666",
              }}
            >
              <div>내 수업 학생들에게만 제공되는</div>
              <div
                style={{
                  paddingTop: "7px",
                }}
              >
                대화형 문제풀이 어플
              </div>
            </div>
            <img
              src={hi}
              style={{
                width: "100%",
                maxWidth: "400px",
                // height: "100px"
              }}
            />
          </>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          maxWidth: "360px",
          flexGrow: 1,
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            padding: "0px 10px 30px 10px",
          }}
        >
          <div
            ref={naverRef}
            id="naverIdLogin"
            style={{
              display: "none",
              width: "100%",
              justifyContent: "center",
            }}
          />
          <UiBtn
            title={
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <img
                  src={logo_naver}
                  style={{
                    height: "22px",
                    marginLeft: "10px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                >
                  네이버로 시작하기
                </div>
              </div>
            }
            btnStyle={{
              // backgroundColor : "#1dc800",
              // margin : "4px 0px",
              height: "54px",
              backgroundColor: "#03C75A",
              width: "100%",
              color: "white",
              border: "none",
            }}
            fnClick={() => {
              naverRef.current.children[0].click();
              // apiPro_test_t()
            }}
          />
          {/* <UiBtn 
                        title={"구글로 시작하기"}
                    /> */}
          <UiBtn
            title={
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <img
                  src={logo_kakao}
                  style={{
                    height: "24px",
                    marginLeft: "10px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                >
                  카카오로 시작하기
                </div>
              </div>
            }
            btnStyle={{
              // margin : "4px 0px",
              height: "54px",
              backgroundColor: "#fee500",
              fontSize: "16px",
              border: "none",
            }}
            fnClick={() => {
              // naver_ref.init();
              // naver_ref.logout();
              // apiPro_test_s()
              setIsLoading(true);
              //alert("start")
              Kakao.Auth.authorize({
                redirectUri: constants.redirect_url,
                // redirectUri : `${constants.apiUrl}/`
              });
              setIsLoading(false);
              // Kakao.Auth.login({
              //     success : (r) => {
              //         // console.log(r)
              //     }
              // })
            }}
          />
          {!util.chk_platform() && (
            <UiBtn
              title={
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <img
                    src={logo_apple}
                    style={{
                      height: "24px",
                      marginLeft: "10px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Apple 로그인
                  </div>
                </div>
              }
              btnStyle={{
                // margin : "4px 0px",
                height: "54px",
                backgroundColor: "black",
                fontSize: "16px",
                border: "none",
              }}
              fnClick={() => {
                if (window.ReactNativeWebView) {
                  // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다.
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      type: "APPLE_LOGIN",
                    })
                  );
                } else {
                  // 모바일이 아니라면 모바일 아님을 alert로 띄웁니다.
                  // alert({ message: ERROR_TYPES.notMobile });
                }
              }}
            />
          )}
          <LinkBox>
            <ContactLink to={"/contactUs"}>문의 하기</ContactLink>
          </LinkBox>
          {constants.isDev && (
            <>
              <UiBtn
                title={"애플로그인"}
                btnStyle={{
                  // margin : "4px 0px",
                  height: "54px",
                  backgroundColor: "#f5b21a",
                  color: "white",
                  border: "none",
                }}
                fnClick={async () => {
                  let isOk = await apiFn.providerSignIn({
                    state: {
                      apple: {
                        id: "1234weasdwe",
                      },
                    },
                  });
                  if (isOk.status === -1) {
                    // alert("2")
                    let appleObj = {
                      email: "email@email",
                      name: "주혜진",
                      oid: "1234weasdwe",
                    };
                    setRegiData({
                      ...appleObj,
                    });
                    if (isOk.status === -1) {
                      navigate("/regiIntro/apple");
                    }
                  } else {
                    try {
                      let isOk_login = await apiFn.fnNewSignIn({
                        state: {
                          id: isOk["id"],
                          pw: "1234weasdwe",
                        },
                      });
                      setUserInfo({
                        ...isOk_login,
                      });
                      if (isOk_login["type"] == 1) {
                        navigate(`/message/student/home`);
                      } else {
                        navigate(`/message/teacher/home`);
                      }
                    } catch (e) {
                      console.error(e);
                      alert("로그인 실패했습니다.");
                      util.removeLocalStorage();
                      navigate("/", { replace: true });
                    }
                  }
                }}
              />
              <UiBtn
                title={"Park juho"}
                btnStyle={{
                  // margin : "4px 0px",
                  height: "54px",
                  backgroundColor: "#f5b21a",
                  color: "white",
                  border: "none",
                }}
                fnClick={() => {
                  apiPro_test_park();
                  // setIsIntro(false)
                  // store.logout_k();
                  // navigate("/")
                }}
              />
              <UiBtn
                title={"s1"}
                btnStyle={{
                  // margin : "4px 0px",
                  height: "54px",
                  backgroundColor: "#f5b21a",
                  color: "white",
                  border: "none",
                }}
                fnClick={() => {
                  apiPro_test_s_1();
                  // setIsIntro(false)
                  // store.logout_k();
                  // navigate("/")
                }}
              />
              <TestButtonList setUserInfo={setUserInfo} />
            </>
          )}
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(200,200,200,0.5)",
          }}
        >
          <CircularProgress
            style={{
              color: style.common.t_color,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Intro;

const LinkBox = styled.div`
  text-align: center;
`;
const ContactLink = styled(Link)`
  font-size: 14px;
  color: #cecece;
`;
